import { logout } from '../utils/login';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import LoadOnce from './loadOnce';
import {
	createSessionStorage,
	USER_ACCOUNT_INFO,
	SITE_ATTEMPTED_WITH_NO_LOGIN,
} from '../utils/sessionStorageManager/sessionStorage';
import { getLocation } from '../utils/url';

import { ShoeberBackendApi } from '../api/ShoeberBackendApi';

export const validTokenActive = () => {
	const userInfoSessionManager = createSessionStorage(
		USER_ACCOUNT_INFO,
		'json'
	);

	if (userInfoSessionManager.get('accessToken') !== null) {
		if (
			userInfoSessionManager.get('updateTime') === null ||
			Date.now() >= parseInt(userInfoSessionManager.get('updateTime'))
		) {
			logout();
		}
	}
};

const VALID_USER_TYPES = ['customer', 'employee', 'admin'];
export const HasRequiredAccountType = (
	userIsSuccessfullyLoggedInCallback,
	validAccountTypes = VALID_USER_TYPES
) => {
	const checkUserType = LoadOnce();

	const history = useHistory();
	if (!Array.isArray(validAccountTypes)) {
		validAccountTypes = [validAccountTypes];
	}

	const accountApi = ShoeberBackendApi.getAccountApi();
	useEffect(() => {
		if (checkUserType.canLoad()) {
			const promise = accountApi.meGet();
			promise.then(
				(response) => {
					if (!validAccountTypes.includes(response.data['user_type'])) {
						history.push('/404');
					} else {
						userIsSuccessfullyLoggedInCallback();
					}
				},
				(failedResponse) => {
					// Runs when user is not logged in
					const siteAttemptedWithNoLoginManager = createSessionStorage(
						SITE_ATTEMPTED_WITH_NO_LOGIN,
						'string'
					);

					siteAttemptedWithNoLoginManager.set(getLocation(true));
					history.push('/login');
				}
			);
		}
	}, [
		accountApi,
		checkUserType,
		history,
		userIsSuccessfullyLoggedInCallback,
		validAccountTypes,
	]);
};
