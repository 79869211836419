import {
	createSessionStorage,
	USER_ACCOUNT_INFO,
	MY_WORKING_ORDER,
} from '../../../utils/sessionStorageManager/sessionStorage';
import { useEffect, useState } from 'react';
import LoadOnce from '../../../hooks/loadOnce';
import { useManagedStorageInput } from '../../../hooks/managedStorageInput';
import ShoeberLine from '../../Core/ShoeberLine';
import { Row, Col, Form } from 'react-bootstrap';
import { formatPhoneNumberDisplay } from '../../../utils/phoneNumber';

export const EMAIL = 'email';
export const PHONE_NUMBER = 'phone_number';

export default function ContactInfo({ errorMessageHash }) {
	const userInfoSessionManager = createSessionStorage(
		USER_ACCOUNT_INFO,
		'json'
	);

	const addManagedSessions = LoadOnce();

	const [userLoggedIn, setUserIsLoggedIn] = useState(true);

	const myWorkingOrderSessionManager = createSessionStorage(
		MY_WORKING_ORDER,
		'json'
	);

	const emailInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		EMAIL
	);

	const phoneNumberInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		PHONE_NUMBER
	);

	useEffect(() => {
		if (addManagedSessions.canLoad()) {
			userInfoSessionManager.addCallback(() => {
				setUserIsLoggedIn(userInfoSessionManager.get('userType') !== undefined);
			});

			emailInput.init();

			phoneNumberInput.setValue(
				formatPhoneNumberDisplay(myWorkingOrderSessionManager.get(PHONE_NUMBER))
			);
		}
	}, [
		addManagedSessions,
		emailInput,
		myWorkingOrderSessionManager,
		phoneNumberInput,
		userInfoSessionManager,
	]);

	return (
		<>
			{!userLoggedIn && (
				<>
					<h3>Contact Info</h3>
					<Row>
						<Col sm>
							<Form.Group>
								<Form.Label>Email</Form.Label>
								<Form.Control
									type='text'
									{...emailInput.bind}
									placeholder='Email'
									isInvalid={errorMessageHash[EMAIL]}
								/>
								<Form.Control.Feedback type='invalid'>
									{errorMessageHash[EMAIL]}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm>
							<Form.Group>
								<Form.Label>Phone Number</Form.Label>
								<Form.Control
									type='text'
									{...phoneNumberInput.bind}
									onChange={(event) =>
										phoneNumberInput.setValue(
											formatPhoneNumberDisplay(event.target.value)
										)
									}
									placeholder='Phone Number'
									isInvalid={errorMessageHash[PHONE_NUMBER]}
								/>
								<Form.Control.Feedback type='invalid'>
									{errorMessageHash[PHONE_NUMBER]}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<ShoeberLine />
				</>
			)}
		</>
	);
}
