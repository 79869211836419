import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import RepairSelect from '../RepairSelect';
import './Home.css'

function Home({ setOpenRunningReceipt, activeEditItem, setActiveEditItem }) {
	return (
		<>
			<Row>
				<Col>
					<h2 className='title'>GIVE A SECOND CHANCE TO YOUR FAVORITE PAIR!</h2>
				</Col>
			</Row>
			<Row style={{paddingBottom: "30px"}}>
				<Col>
					<RepairSelect
						setOpenRunningReceipt={setOpenRunningReceipt}
						activeEditItem={activeEditItem}
						setActiveEditItem={setActiveEditItem}
					/>
				</Col>
			</Row>
		</>
	);
}

export default withRouter(Home);
