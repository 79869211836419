import StorageManager from '../storageManager';
import { LOCAL_STORAGE_VERSION } from '../version';

const LOCAL_STORAGE_VERSION_NAME = 'localStorageVersion';

class LocalStorageManager extends StorageManager {
	constructor() {
		super();
		if (!LocalStorageManager.instance) {
			LocalStorageManager.instance = this;
		}

		const localStorageVersion = localStorage.getItem(
			LOCAL_STORAGE_VERSION_NAME
		);

		if (process.env.REACT_APP_CLEAR_LOCAL_STORAGE_ON_RELOAD === true) {
			localStorage.clear();
		}

		if (localStorageVersion !== LOCAL_STORAGE_VERSION) {
			localStorage.clear();
			localStorage.setItem(LOCAL_STORAGE_VERSION_NAME, LOCAL_STORAGE_VERSION);
		}
		
		return LocalStorageManager.instance;
	}

	getImpl(itemName) {
		return localStorage.getItem(itemName);
	}

	setImpl(itemName, value) {
		localStorage.setItem(itemName, value);
	}

	removeImpl(itemName) {
		localStorage.removeItem(itemName);
	}
}

const instance = new LocalStorageManager();
Object.freeze(instance);
export default instance;
