import { Row, Col, Modal, Image } from 'react-bootstrap';
import { display } from '../../../utils/display';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import LoadOnce from '../../../hooks/loadOnce';
import {
	createLocalStorage,
	MY_REPAIR_ITEMS_NAME,
} from '../../../utils/localStorageManager/localStorage';
import { theme } from '../../../theme';
import { format } from 'react-string-format';
import { BsTrash } from 'react-icons/bs';
import { FaImage } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import useModal from '../../../hooks/useModal';
import ShoeberButton from '../ShoeberButton';
import ModalCloseButton from '../Modal/ModalCloseButton';
import { useHistory } from 'react-router-dom';
import ShoeberLine from '../ShoeberLine';
import NotesPopover from '../../RunningReceipt/NotesPopover';

const StyledIcon = styled.div`
	font-size: 25px;

	&:hover {
		color: ${({ theme }) => theme.primaryHover};
		cursor: pointer;
		transform: scale(1.2);
	}

	a {
		color: ${({ color }) => color};
		&:hover {
			color: ${({ theme }) => theme.primaryHover};
		}
	}
`;

const StyledRequestRepairHeaders = styled.h6`
	text-decoration: underline;
`;

const StyledRequestCategoryHeaders = styled.h5`
	text-decoration: underline;
`;

const StyledRequestRepairCategory = styled.span`
	font-weight: bold;
`;

export default function MyCartItems({ setActiveEditItem, color = undefined }) {
	color = color || theme.primaryLight;

	const [selectedItem, setSelectedItem] = useState(undefined);

	const displayModal = useModal();

	const [myCart, setMyCart] = useState([]);
	const addMyRepairItemsManager = LoadOnce();
	const myCartLocalStorage = createLocalStorage(MY_REPAIR_ITEMS_NAME, 'array');

	const history = useHistory();

	useEffect(() => {
		if (addMyRepairItemsManager.canLoad()) {
			myCartLocalStorage.addCallback(() => setMyCart(myCartLocalStorage.get()));
		}
	}, [addMyRepairItemsManager, myCartLocalStorage]);

	function deleteItem(itemIndex) {
		let newCart = myCart.filter((_, i) => i !== itemIndex);
		myCartLocalStorage.set(newCart);
	}

	return (
		<>
			{myCart.map((item, itemIndex) => {
				item.editIndex = itemIndex;
				return (
					<div key={itemIndex}>
						<Row>
							<Col xs={8} style={{ paddingTop: '15px' }}>
								{format(
									'{0} {1} {2} {3} from {4}, has {5} repair{6}.',
									display(item.color),
									display(item.brand),
									display(item.type.name),
									display(item.name),
									display(item.category),
									item.repairsRequested.length,
									item.repairsRequested.length > 1 ? 's' : ''
								)}
							</Col>
							<Col className='d-flex justify-content-end'>
								<StyledIcon style={{ marginRight: '20px' }} color={color}>
									{/* Display image */}
									{/* <FaImage
										onClick={() => {
											setSelectedItem(item);
											displayModal.toggle();
										}}
									/> */}
								</StyledIcon>
								<StyledIcon style={{ marginRight: '20px' }} color='black'>
									{/* Edit Item */}
									<Link
										onClick={() => {
											setActiveEditItem(item);
										}}
										to='/home'>
										<MdEdit />
									</Link>
								</StyledIcon>
								<NotesPopover
									
									StyledIcon={StyledIcon}
								/>
								<StyledIcon onClick={() => deleteItem(itemIndex)} color={color}>
									{/* Delete item */}
									<BsTrash />
								</StyledIcon>
							</Col>
						</Row>
						<ShoeberLine />
					</div>
				);
			})}
			{selectedItem !== undefined && (
				<Modal
					show={displayModal.isShowing}
					onHide={displayModal.toggle}
					animation={false}>
					<Modal.Header>
						<Modal.Title>{selectedItem.name}</Modal.Title>
						<ModalCloseButton onClick={displayModal.toggle} />
					</Modal.Header>
					<Modal.Body>
						<Row>
							<Col>
								<Image
									width='100%'
									alt={selectedItem['name']}
									src={selectedItem['image']}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<StyledRequestCategoryHeaders>
									{display(selectedItem.category)}
								</StyledRequestCategoryHeaders>
							</Col>
						</Row>
						<Row>
							<Col>
								<StyledRequestRepairCategory>
									Color:
								</StyledRequestRepairCategory>{' '}
								{selectedItem.color ? display(selectedItem.color) : 'Unknown'}
							</Col>
							<Col>
								<StyledRequestRepairCategory>
									Brand:
								</StyledRequestRepairCategory>{' '}
								{selectedItem.brand ? display(selectedItem.brand) : 'Unknown'}
							</Col>
							<Col>
								<StyledRequestRepairCategory>Type:</StyledRequestRepairCategory>{' '}
								{display(selectedItem.type.name)}
							</Col>
						</Row>
						<Row style={{ marginTop: '8px' }}>
							<Col>
								<StyledRequestRepairHeaders>
									Requested Repairs
								</StyledRequestRepairHeaders>
							</Col>
						</Row>
						{selectedItem.repairsRequested.map((repair, index) => {
							return (
								<Row key={index}>
									<Col>
										{repair.display_name}: {repair.description}
									</Col>
								</Row>
							);
						})}
					</Modal.Body>
					<Modal.Footer>
						<ShoeberButton
							onClick={() => {
								displayModal.toggle();
								setActiveEditItem(selectedItem);
								history.push('/home');
							}}
							text='Edit'
						/>
						<ShoeberButton
							onClick={displayModal.toggle}
							secondary
							text='Close'
						/>
					</Modal.Footer>
				</Modal>
			)}
		</>
	);
}
