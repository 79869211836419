import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import LoadOnce from '../../hooks/loadOnce';
import { ShoeberBackendApi } from '../../api/ShoeberBackendApi';
import { useEffect, useState, useRef } from 'react';
import {
	StyledProductSelect,
	StyledProductSelectButton,
} from './RepairSelect.styled';
import { theme } from '../../theme';
import { format } from 'react-string-format';
import useModal from '../../hooks/useModal';
import RepairSelectModal from './RepairSelectModal';
import RepairChildSelectModal from './RepairChildSelectModal';
import Loading from '../Core/Loading/Loading';
import {
	createLocalStorage,
	TYPES_BY_CATEGORY,
	SHOEBER_CATEGORIES,
} from '../../utils/localStorageManager/localStorage';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { useCreateRows } from '../../hooks/useScreenWidth';

function RepairSelect({
	setOpenRunningReceipt,
	activeEditItem,
	setActiveEditItem,
}) {
	// APIs
	const categoryImageApi = ShoeberBackendApi.getCategoryImageApi();
	const productApi = ShoeberBackendApi.getProductApi();

	// Errors
	const [errorDisplay, setErrorDisplay] = useState(undefined);

	// Categories
	const loadProductCategories = LoadOnce();
	const [categories, setCategories] = useState({});
	const [categoriesIsLoading, setCategoriesIsLoading] = useState(false);

	const shoeberCategoryLocalStorageManager = createLocalStorage(
		SHOEBER_CATEGORIES,
		'array',
		undefined,
		3600000
	);

	const categoryRows = useCreateRows(
		Object.keys(categories).map((categoryName) => {
			return {
				name: categoryName,
				image: categories[categoryName],
			};
		}),
		1,
		3
	);

	// User selection
	const [selectedCategoryName, setSelectedCategoryName] = useState(undefined);

	const [canLoadProductTypes, setCanLoadProductTypes] = useState(false);
	const loadProductTypes = LoadOnce();

	// Types
	const [typesByCategory, setTypesByCategory] = useState({});

	const shoeberTypesByCategoryLocalStorageManager = createLocalStorage(
		TYPES_BY_CATEGORY,
		'json',
		undefined,
		3600000
	);

	const selectedCategoryTypeRows = useCreateRows(
		Object.keys(
			typesByCategory[selectedCategoryName] !== undefined
				? typesByCategory[selectedCategoryName]
				: {}
		).map((typeName) => {
			return {
				name: typeName,
				image: typesByCategory[selectedCategoryName][typeName],
			};
		})
	);


	//Modals
	const itemSelectModal = useModal();
	const repairChildSelectModal = useModal();

	//Items
	const [childRepairs, setChildRepairs] = useState(undefined);
	const [repairParent, setRepairParent] = useState(undefined);

	const [itemRepairs, setItemRepairs] = useState([]);


	// User section
	const [selectedType, setSelectedType] = useState(undefined);
	const [selectedItem, setSelectedItem] = useState(undefined);

	function toggleRepair(repair) {
		let updatedRepairs = [];

		let repairExisted = false;

		for (let i=0; i< itemRepairs.length; i++) {
			let existingRepair = itemRepairs[i];
			if (existingRepair['uuid'] !== repair['uuid']) {
				updatedRepairs.push(existingRepair);
			}else{
				repairExisted = true;
			}
		}
		if (!repairExisted) {
			updatedRepairs = [...updatedRepairs, repair];
		}

		setItemRepairs(updatedRepairs);
	}

	const modalRef = useRef();
	useOnClickOutside(modalRef, () => itemSelectModal.toggle);

	useEffect(() => {
		if (loadProductCategories.canLoad()) {
			shoeberCategoryLocalStorageManager.addCallback(() => {
				setCategories(shoeberCategoryLocalStorageManager.get());
			});

			shoeberTypesByCategoryLocalStorageManager.addCallback(() => {
				setTypesByCategory(shoeberTypesByCategoryLocalStorageManager.get());
			});

			let currentShoeberCategories = shoeberCategoryLocalStorageManager.get();
			if (currentShoeberCategories.length === 0) {
				setCategoriesIsLoading(true);
				categoryImageApi
					.productImageCategoryGet()
					.then((response) => {
						shoeberCategoryLocalStorageManager.set(response.data);
						setCanLoadProductTypes(true);
						setCategoriesIsLoading(false);
					})
					.catch((errorResponse) => {
						setErrorMessage(
							'Sorry we are having trouble loading our products at this time.'
						);
						setCategoriesIsLoading(false);
					});
			} else {
				setCategories(currentShoeberCategories);
				setCanLoadProductTypes(true);
			}
		}

		if (canLoadProductTypes === true && loadProductTypes.canLoad()) {
			let productCategoryTypes = shoeberTypesByCategoryLocalStorageManager.get();
			Object.keys(categories).forEach((category) => {
				if (productCategoryTypes[category] === undefined) {
					productApi
						.productGet('true', category)
						.then((response) => {
							shoeberTypesByCategoryLocalStorageManager.set(
								response.data,
								category
							);
						})
						.catch((errorResponse) => {
							setErrorMessage(
								format(
									'Sorry we are having trouble loading our {0} products at this time.',
									category
								)
							);
						});
				} else {
					let type = {};
					type[categories] = productCategoryTypes[category];
					setTypesByCategory(Object.assign({}, typesByCategory, type));
				}
			});
		}

		if (activeEditItem !== undefined) {
			setSelectedCategoryName(activeEditItem.category);
		}
	}, [
		activeEditItem,
		canLoadProductTypes,
		categories,
		categoryImageApi,
		loadProductCategories,
		loadProductTypes,
		productApi,
		shoeberCategoryLocalStorageManager,
		shoeberTypesByCategoryLocalStorageManager,
		typesByCategory,
	]);

	const setErrorMessage = (message) => {
		message = typeof message === 'string' ? message : 'An error occurred';
		setErrorDisplay(
			<Row>
				<Col>
					<span style={{ color: theme.errorMessagePrimary }}>{message}</span>
				</Col>
			</Row>
		);
	};

	return (
		<Container style={{ paddingTop: '10px' }}>
			<Loading loading={categoriesIsLoading} />
			{categoryRows.rows.length > 0 &&
				categoryRows.rows.map((row, rowIndex) => {
					return (
						<Row key={rowIndex}>
							{row.map((category, categoryIndex) => {
								return (
									<Col
										xs={categoryRows.columnSize}
										style={{ marginBottom: '10px' }}
										key={format('row{0}-column{1}', rowIndex, categoryIndex)}>
										<StyledProductSelect>
											<StyledProductSelectButton
												onClick={() => {
													setSelectedCategoryName(category['name']);
													itemSelectModal.toggle();
												}}>
												<Image alt={category['name']} src={category['image']} />
												<h2>{category['name']}</h2>
											</StyledProductSelectButton>
										</StyledProductSelect>
									</Col>
								);
							})}
						</Row>
					);
				})}
			{errorDisplay !== undefined && errorDisplay}
			<>
				<RepairSelectModal
					isShowing={itemSelectModal.isShowing}
					toggle={itemSelectModal.toggle}
					categoryName={selectedCategoryName}
					productTypeRows={selectedCategoryTypeRows}
					setOpenRunningReceipt={setOpenRunningReceipt}
					activeEditItem={activeEditItem}
					setActiveEditItem={setActiveEditItem}
					setChildRepairs={setChildRepairs}
					repairChildSelectModal={repairChildSelectModal}
					setRepairParent={setRepairParent}
					toggleRepair={toggleRepair}
					itemRepairs={itemRepairs}
					setItemRepairs={setItemRepairs}
					selectedType={selectedType}
					setSelectedType={setSelectedType}
					selectedItem={selectedItem}
					setSelectedItem={setSelectedItem}
				/>
				<RepairChildSelectModal
					isShowing={repairChildSelectModal.isShowing}
					toggle={repairChildSelectModal.toggle}
					repairs={childRepairs}
					repairParent={repairParent}
					toggleRepair={toggleRepair}
					itemRepairs={itemRepairs}
				/>
			</>
		</Container>
	);
}

export default withRouter(RepairSelect);
