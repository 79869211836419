import React, { useRef, useEffect, useState } from 'react';
import './RepairSelect.css';
import { Modal, Row, Col, Image, Carousel, Form } from 'react-bootstrap';
import RepairSelectChecks from './RepairSelectChecks.js';
import { format } from 'react-string-format';
import { display } from '../../utils/display';
import {
	createLocalStorage,
	SHOEBER_PRODUCTS,
	MY_REPAIR_ITEMS_NAME,
} from '../../utils/localStorageManager/localStorage';
import LoadOnce from '../../hooks/loadOnce';
import { ShoeberBackendApi } from '../../api/ShoeberBackendApi';
import styled from 'styled-components';
import {
	StyledTypeSelect,
	StyledTypeSelectButton,
} from './RepairSelectModal.styled';
import { useInput } from '../../hooks/input';
import ModalCloseButton from '../Core/Modal/ModalCloseButton';
import ShoeberButton from '../Core/ShoeberButton';
import ModalBackButton from '../Core/Modal/ModalBackButton';
import { useCreateRows } from '../../hooks/useScreenWidth';

const StyledCarousel = styled(Carousel)`
	span.carousel-control-next-icon,
	span.carousel-control-prev-icon {
		filter: invert(100%);
	}
`;

export default function RepairSelectModal({
	isShowing,
	toggle,
	categoryName,
	productTypeRows,
	setOpenRunningReceipt,
	activeEditItem,
	setActiveEditItem,
	setChildRepairs,
	repairChildSelectModal,
	setRepairParent,
	toggleRepair,
	itemRepairs,
	setItemRepairs,
	selectedType,
	setSelectedType,
	selectedItem,
	setSelectedItem,
}) {
	// Apis
	const productApi = ShoeberBackendApi.getProductApi();

	// Product
	const productListLocalStorageManager = createLocalStorage(
		SHOEBER_PRODUCTS,
		'json'
	);
	const [products, setProducts] = useState({});

	// Item
	const itemRows = useCreateRows(
		selectedType !== undefined && products[categoryName] !== undefined
			? products[categoryName][selectedType.name]
			: []
	);
	const addMyRepairItemsManager = LoadOnce();

	// User cart
	const myRepairItemsLocalStorageManager = createLocalStorage(
		MY_REPAIR_ITEMS_NAME,
		'array'
	);

	const {
		value: itemColor,
		bind: bindItemColor,
		setValue: setItemColor,
	} = useInput('');

	const {
		value: itemBrand,
		bind: bindItemBrand,
		setValue: setItemBrand,
	} = useInput('');

	const {
		value: itemCustomerNotes,
		bind: bindItemCustomerNotes,
		setValue: setItemCustomerNotes,
	} = useInput('');

	function closeModal() {
		if (isShowing && activeEditItem !== undefined) {
			setActiveEditItem(undefined);
		}

		toggle();
	}

	function addSelectedItem() {
		let newItem = Object.assign({}, selectedItem, {
			repairsRequested: itemRepairs,
			type: selectedType,
			category: categoryName,
			color: itemColor,
			brand: itemBrand,
			customerNotes: itemCustomerNotes,
		});

		let itemAdded = false;
		if (activeEditItem !== undefined) {
			let myCart = myRepairItemsLocalStorageManager.get();

			if (
				activeEditItem.editIndex !== undefined &&
				myCart.length > activeEditItem.editIndex
			) {
				delete newItem['editIndex'];
				myCart[activeEditItem.editIndex] = newItem;
				myRepairItemsLocalStorageManager.set(myCart);
				itemAdded = true;
			}

			setActiveEditItem(undefined);
		}

		if (itemAdded === false) {
			myRepairItemsLocalStorageManager.add(newItem);
		}
		closeModal();
		setOpenRunningReceipt(true);
	}

	// Modal references
	const modalHeader = useRef(null);
	const modalFooter = useRef(null);

	useEffect(() => {
		if (isShowing) {
			modalHeader.current.focus();
		} else if (selectedType !== undefined || selectedItem !== undefined) {
			setSelectedType(undefined);
			setSelectedItem();
			setItemRepairs([]);
			setItemCustomerNotes('');
			setItemBrand('');
			setItemColor('');
		}

		if (addMyRepairItemsManager.canLoad()) {
			productListLocalStorageManager.addCallback(() => {
				setProducts(productListLocalStorageManager.get());
			});

			if (Object.keys(productListLocalStorageManager.get()).length === 0) {
				let productsTemp = {};
				productApi.productGet().then((response) => {
					Object.keys(response.data).forEach((category) => {
						productsTemp[category] = {};
						Object.keys(response.data[category].types).forEach((type) => {
							productsTemp[category][type] =
								response.data[category].types[type].items;
						});
						productListLocalStorageManager.set(productsTemp);
					});
				});
			}
		}

		if (
			activeEditItem !== undefined &&
			products[categoryName] !== undefined &&
			isShowing === false
		) {
			toggle();
			setItemColor(activeEditItem.color);
			setItemBrand(activeEditItem.brand);
			setSelectedType(activeEditItem.type);
			setItemCustomerNotes(activeEditItem.customerNotes);
			setItemRepairs(activeEditItem.repairsRequested);
			setSelectedItem(activeEditItem);
		}
	}, [
		activeEditItem,
		addMyRepairItemsManager,
		categoryName,
		isShowing,
		productApi,
		productListLocalStorageManager,
		products,
		selectedItem,
		selectedType,
		setItemBrand,
		setItemColor,
		setItemCustomerNotes,
		setItemRepairs,
		setSelectedItem,
		setSelectedType,
		toggle,
	]);

	const handleBack = () => {
		if (selectedItem !== undefined) {
			setItemRepairs([]);
			setSelectedItem(undefined);
		} else if (selectedType !== undefined) {
			setSelectedType(undefined);
		}
	};

	const selectType = (type) => {
		modalHeader.current.focus();
		let typeName = type['name'];
		products[categoryName][typeName].forEach((item) => {
			if (item.images === undefined) {
				productApi
					.productCleanCloudIdGet(item.clean_cloud_id)
					.then((response) => {
						let currentProducts = productListLocalStorageManager.get();
						currentProducts[categoryName][typeName].forEach((currentItem) => {
							if (currentItem.clean_cloud_id === item.clean_cloud_id) {
								currentItem.images = response.data.images;
								currentItem.repairs = response.data.repairs;
								productListLocalStorageManager.set(currentProducts);
							}
						});
					});
			}
		});

		setSelectedType(type);
	};

	const selectItem = (item) => {
		modalHeader.current.focus();
		setSelectedItem(item);
	};

	return (
		<Modal
			dialogClassName='my-modal'
			minWidth={'195vw'}
			show={isShowing}
			onHide={closeModal}
			animation={false}
			style={{ whiteSpace: 'nowrap' }}>
			<Modal.Header ref={modalHeader}>
				<ModalBackButton onClick={handleBack} />
				<Modal.Title>
					{selectedItem !== undefined && display(selectedItem.name)}
					{selectedItem === undefined &&
						selectedType !== undefined &&
						display(selectedType.name)}
					{selectedItem === undefined &&
						selectedType === undefined &&
						display(categoryName)}
				</Modal.Title>
				<ModalCloseButton onClick={closeModal} />
			</Modal.Header>

			<Modal.Body>
				{productTypeRows !== undefined &&
					selectedType === undefined &&
					productTypeRows.rows.map((row, rowIndex) => {
						return (
							<Row key={rowIndex} style={{ marginBottom: '5px' }}>
								{row.map((type, typeIndex) => {
									return (
										<Col
											style={{ marginBottom: '5px', marginRight: '20px' }}
											xs={5}
											key={format('row{0}-column{1}', rowIndex, typeIndex)}
											onClick={() => selectType(type)}>
											<StyledTypeSelect>
												<StyledTypeSelectButton>
													<Image alt={type['name']} src={type['image']} />
													<p>{display(type['name'])}</p>
												</StyledTypeSelectButton>
											</StyledTypeSelect>
										</Col>
									);
								})}
							</Row>
						);
					})}
				{selectedType !== undefined &&
					selectedItem === undefined &&
					itemRows.rows.map((itemRow, rowIndex) => {
						return (
							<Row key={rowIndex} style={{ marginBottom: '2px' }}>
								{itemRow.map((item, itemIndex) => {
									return (
										<Col
											className='col-3'
											style={{ marginBottom: '5px' }}
											xs={5}
											key={format('row{0}-column{1}', rowIndex, itemIndex)}
											onClick={() => selectItem(item)}>
											<StyledTypeSelect>
												<StyledTypeSelectButton>
													<Image
														alt={item['name']}
														src={
															item['images'] !== undefined
																? item['images'][0]
																: 'https://s3.us-east-2.amazonaws.com/shoeber.html.helpers.graffititech.co/images/static/loading.gif'
														}
													/>
													<p>{display(item['name'])}</p>
												</StyledTypeSelectButton>
											</StyledTypeSelect>
										</Col>
									);
								})}
							</Row>
						);
					})}
				{selectedItem !== undefined && (
					<Row>
						<Col style={{ overflow: 'scroll' }}>
							<div>
								<Row style={{ justifyContent: 'space-evenly' }}>
									<Col
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'column',
											justifyContent: 'center',
											maxWidth: '1000px',
										}}>
										<StyledCarousel
											interval={null}
											style={{
												padding: '8%',
												maxHeight: '95vh',
												height: '100%',
												width: '100%',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}>
											{selectedItem['images'].map((src, srcIndex) => {
												return (
													<Carousel.Item key={srcIndex}>
														<div
															style={{
																position: 'absolute',
																display: 'flex',
																alignItems: 'center',
																width: '20%',
																marginRight: '10%',
																height: '100%',
																zIndex: 2,
																left: '5%',
																top: '0%',
															}}>
															<RepairSelectChecks
																repairs={selectedItem.repairs}
																repairOffset={srcIndex * 2}
																toggleRepair={toggleRepair}
																itemRepairs={itemRepairs}
																repairChildSelectModal={repairChildSelectModal}
																setRepairParent={setRepairParent}
																setChildRepairs={setChildRepairs}
															/>
														</div>
														<img
															style={{
																height: '100%',
																margin: '0',
																width: '100%',
															}}
															className='d-flex'
															src={src}
															alt={selectedItem['name']}
														/>
														<div
															style={{
																position: 'absolute',
																display: 'flex',
																alignItems: 'center',
																width: '20%',
																marginRight: '0%',
																height: '100%',
																zIndex: 2,
																top: '0%',
																right: '5%',
															}}>
															<RepairSelectChecks
																repairs={selectedItem.repairs}
																repairOffset={srcIndex * 2 + 1}
																toggleRepair={toggleRepair}
																itemRepairs={itemRepairs}
																repairChildSelectModal={repairChildSelectModal}
																setRepairParent={setRepairParent}
																setChildRepairs={setChildRepairs}
															/>
														</div>
													</Carousel.Item>
												);
											})}
										</StyledCarousel>
									</Col>
									<Col md={3} sm={12}>
										<Row>
											<Col>
												<Form>
													<Form.Group>
														<Form.Label>Color</Form.Label>
														<Form.Control
															type='text'
															{...bindItemColor}
															placeholder='Color'
														/>
													</Form.Group>
													<Form.Group>
														<Form.Label>Brand</Form.Label>
														<Form.Control
															type='text'
															{...bindItemBrand}
															placeholder='Brand'
														/>
													</Form.Group>
													<Form.Group>
														<Form.Label>Additional Info</Form.Label>
														<Form.Control
															placeholder='Additional Info'
															as='textarea'
															{...bindItemCustomerNotes}
														/>
													</Form.Group>
												</Form>
											</Col>
										</Row>
										<>
											{itemRepairs && itemRepairs.length > 0 && (
												<>
													<p>Selected Repairs</p>
													<ul>
														{itemRepairs.map(
															(selectedRepair, selectedRepairIndex) => {
																return (
																	<li>
																		{selectedRepair['name']} $
																		{selectedRepair['price']}
																	</li>
																);
															}
														)}
													</ul>
												</>
											)}

											{(!itemRepairs || itemRepairs.length === 0) && (
												<>Add repairs.</>
											)}
										</>
									</Col>
								</Row>
							</div>
							}
						</Col>
					</Row>
				)}
			</Modal.Body>

			<Modal.Footer ref={modalFooter}>
				{selectedItem !== undefined && (
					<>
						<ShoeberButton
							onClick={addSelectedItem}
							disabled={itemRepairs.length === 0}
							text={activeEditItem === undefined ? 'Add Item' : 'Update Item'}
						/>
						<ShoeberButton onClick={closeModal} secondary text='Cancel' />
					</>
				)}
			</Modal.Footer>
		</Modal>
	);
}
