import styled from 'styled-components';

export const StyledProductSelect = styled.div`
	img {
		max-width: 100%;
		margin-top: 8px;
	}

	color: ${({ theme }) => theme.orange};


	&:hover {
		transform: scale(1.008);
	}
`;

const Button = styled.button`
	color: ${({ theme }) => theme.primaryLight};

`;

export const StyledProductSelectButton = styled(Button)`
	background: ${({ theme }) => theme.primaryLight};
	border: 4px solid;
	border-radius: 10%;
	border-color: ${({ theme }) => theme.orange};


	&:hover {
		color: ${({ theme }) => theme.primaryHover};
		background: ${({ theme }) => theme.grayFour};
		border-color: ${({ theme }) => theme.orange};
	}

	&:focus {
		outline-color: ${({ theme }) => theme.primaryDark};
	}

	&:active {
		background: ${({ theme }) => theme.primaryLight};
		border-color: ${({ theme }) => theme.orange};
	}
`;