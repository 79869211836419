import {
	createSessionStorage,
	MY_WORKING_ORDER,
} from '../../../utils/sessionStorageManager/sessionStorage';
import { useManagedStorageInput } from '../../../hooks/managedStorageInput';
import LoadOnce from '../../../hooks/loadOnce';
import { useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';

export const BILLING_ADDRESS_ONE = 'billing_address_one';
export const BILLING_ADDRESS_TWO = 'billing_address_two';
export const BILLING_POSTAL_CODE = 'billing_postal_code';
export const BILLING_CITY = 'billing_city';
export const BILLING_STATE = 'billing_state';
export const BILLING_COUNTRY = 'billing_country';

export default function BillingAddress({ errorMessageHash }) {
	const myWorkingOrderSessionManager = createSessionStorage(
		MY_WORKING_ORDER,
		'json'
	);

	const loadBillingAddressData = LoadOnce();

	const billingAddressOneInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		BILLING_ADDRESS_ONE
	);

	const billingAddressTwoInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		BILLING_ADDRESS_TWO
	);

	const billingPostalCodeInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		BILLING_POSTAL_CODE
	);

	const billingCityInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		BILLING_CITY
	);

	const billingStateInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		BILLING_STATE
	);

	const billingCountryInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		BILLING_COUNTRY
	);

	useEffect(() => {
		if (loadBillingAddressData.canLoad()) {
			billingAddressOneInput.init();
			billingAddressTwoInput.init();
			billingPostalCodeInput.init();
			billingCityInput.init();
			billingStateInput.init();
			billingCountryInput.init('US');
		}
	}, [
		loadBillingAddressData,
		myWorkingOrderSessionManager,
		billingAddressOneInput,
		billingAddressTwoInput,
		billingPostalCodeInput,
		billingCityInput,
		billingStateInput,
		billingCountryInput,
	]);

	return (
		<>
			<h3>Billing Address</h3>
			<Row>
				<Col md>
					<Form.Group>
						<Form.Label>Billing Address Line One</Form.Label>
						<Form.Control
							type='text'
							{...billingAddressOneInput.bind}
							placeholder='Billing Address Line One'
							required
							isInvalid={errorMessageHash[BILLING_ADDRESS_ONE]}
						/>
						<Form.Control.Feedback type='invalid'>
							{errorMessageHash[BILLING_ADDRESS_ONE]}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
				<Col md>
					<Form.Group>
						<Form.Label>Billing Address Line Two</Form.Label>
						<Form.Control
							type='text'
							{...billingAddressTwoInput.bind}
							placeholder='Billing Address Line Two'
							isInvalid={errorMessageHash[BILLING_ADDRESS_TWO]}
						/>
						<Form.Control.Feedback type='invalid'>
							{errorMessageHash[BILLING_ADDRESS_TWO]}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col sm>
					<Form.Group>
						<Form.Label>Billing Postal Code</Form.Label>
						<Form.Control
							type='text'
							{...billingPostalCodeInput.bind}
							placeholder='Billing Postal Code'
							required
							isInvalid={errorMessageHash[BILLING_POSTAL_CODE]}
						/>
						<Form.Control.Feedback type='invalid'>
							{errorMessageHash[BILLING_POSTAL_CODE]}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
				<Col sm>
					<Form.Group>
						<Form.Label>Billing City</Form.Label>
						<Form.Control
							type='text'
							{...billingCityInput.bind}
							placeholder='Billing City'
							isInvalid={errorMessageHash[BILLING_CITY]}
						/>
						<Form.Control.Feedback type='invalid'>
							{errorMessageHash[BILLING_CITY]}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
				<Col sm>
					<Form.Group>
						<Form.Label>Billing State</Form.Label>
						<Form.Control
							type='text'
							{...billingStateInput.bind}
							placeholder='Billing State'
							isInvalid={errorMessageHash[BILLING_STATE]}
						/>
						<Form.Control.Feedback type='invalid'>
							{errorMessageHash[BILLING_STATE]}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
				<Col sm>
					<Form.Group>
						<Form.Label>Billing Country</Form.Label>
						<Form.Control
							type='text'
							{...billingCountryInput.bind}
							isInvalid={errorMessageHash[BILLING_COUNTRY]}
							placeholder='Billing Country'
						/>
						<Form.Control.Feedback type='invalid'>
							{errorMessageHash[BILLING_COUNTRY]}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
			</Row>
		</>
	);
}
