import React from 'react';
import { theme } from '../../theme';

export default function ShoeberErrorMessage({
	message = undefined,
	style = {},
}) {
	return (
		<>
			{message && (
				<p
					style={Object.assign(
						{},
						{
							color: theme.errorMessagePrimary,
						},
						style
					)}>
					{message}
				</p>
			)}
		</>
	);
}
