import { useHistory } from 'react-router-dom';
import { getLocation, hasParam } from '../utils/url';

export default function useClearUrlParams() {
	const history = useHistory();

	function clear() {
		history.push(getLocation(false));
	}

	function clearIfExists(urlParams) {
		urlParams = Array.isArray(urlParams) ? urlParams : [urlParams];

		urlParams.forEach((urlParamName) => {
			if (hasParam(urlParamName)) {
				clear();
			}
		});
	}

	return {
		clear,
		clearIfExists,
	};
}
