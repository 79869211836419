import { Modal, Row, Col } from 'react-bootstrap';
import ShoeberButton from '../../Core/ShoeberButton';
import LoadOnce from '../../../hooks/loadOnce';
import ModalCloseButton from '../../Core/Modal/ModalCloseButton';
import ModalBackButton from '../../Core/Modal/ModalBackButton';
import GoogleMapReact from 'google-map-react';
import LocationMarker from '../../Core/LocationMarker';
import { ShoeberBackendApi } from '../../../api/ShoeberBackendApi';
import { useEffect, useState } from 'react';
import { format } from 'react-string-format';

export default function LocationSelectModal({
	isShowing,
	toggle,
	setLocationInformation,
	selectedLocation,
	setSelectedLocation,
}) {
	const defaultMapInformation = {
		center: {
			lat: 34.0755429,
			lng: -118.3756987,
		},
		zoom: 10,
	};

	const loadMapData = LoadOnce();

	const [locationKey, setLocationKey] = useState(0);

	const [locations, setLocations] = useState([]);

	useEffect(() => {
		if (loadMapData.canLoad()) {
			ShoeberBackendApi.getDropboxApi()
				.dropboxGet()
				.then((response) => {
					let newLocations = locations;
					response.data.forEach((location) => {
						newLocations.push(location);
					});
					setLocations(newLocations);
					setLocationKey(newLocations.length);
				});
		}
	}, [loadMapData, locations]);

	function handleBack() {
		if (selectedLocation !== undefined) {
			setSelectedLocation(undefined);
		} else {
			toggle();
		}
	}

	function handleSelectLocation() {
		toggle();
		setLocationInformation(selectedLocation.identifier);
		setSelectedLocation(undefined);
	}

	return (
		<Modal
			size={selectedLocation === undefined ? 'lg' : ''}
			show={isShowing}
			onHide={toggle}
			animation={false}>
			<Modal.Header>
				<ModalBackButton onClick={handleBack} />
				<Modal.Title>
					{selectedLocation === undefined
						? 'Select Location'
						: selectedLocation.location.address_one}
				</Modal.Title>
				<ModalCloseButton onClick={toggle} />
			</Modal.Header>
			<Modal.Body>
				{selectedLocation === undefined && (
					<div style={{ height: '500px', width: '100%' }}>
						<GoogleMapReact
							bootstrapURLKeys={{
								key: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY,
							}}
							key={locationKey}
							defaultCenter={defaultMapInformation.center}
							defaultZoom={defaultMapInformation.zoom}>
							{locations.map((locationMarker, index) => {
								let lat = locationMarker.location.geocode.lat;
								let lng = locationMarker.location.geocode.lng;
								return (
									<LocationMarker
										lat={lat}
										lng={lng}
										key={format('{lat:{0}-lng:{1}-{2}', lat, lng, index)}
										info={locationMarker}
										setLocationModalInfo={setSelectedLocation}
										toggle={() => {}}
									/>
								);
							})}
						</GoogleMapReact>
					</div>
				)}
				{selectedLocation !== undefined && (
					<>
						<Row>
							<Col>
								<img
									alt={selectedLocation.location.address_one}
									src={selectedLocation.image}
									style={{ maxWidth: '100%' }}
								/>
							</Col>
							<Col>
								<p>
									{selectedLocation.location.address_one}
									{selectedLocation.location.address_two != null &&
										format(' {0}', selectedLocation.location.address_two)}
								</p>
								<p>
									{selectedLocation.location.city}{' '}
									{selectedLocation.location.state}
								</p>
								<p>{selectedLocation.location.postal_code}</p>
								<p>{selectedLocation.location.country}</p>
							</Col>
						</Row>
					</>
				)}
			</Modal.Body>
			<Modal.Footer>
				<ShoeberButton
					onClick={handleSelectLocation}
					disabled={!selectedLocation}
					text='Select Location'
				/>
				<ShoeberButton onClick={toggle} secondary text='Cancel' />
			</Modal.Footer>
		</Modal>
	);
}
