import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { ShoeberBackendApi } from '../../api/ShoeberBackendApi';
import './Login.css';
import ShoeberButton from '../Core/ShoeberButton';
import ShoeberLink from '../Core/ShoeberLink';
import { useEffect, useState } from 'react';
import { setLogin, logout } from '../../utils/login';
import LoadOnce from '../../hooks/loadOnce';
import Loading from '../Core/Loading/Loading';
import { useInput } from '../../hooks/input';
import {
	createSessionStorage,
	SITE_ATTEMPTED_WITH_NO_LOGIN,
} from '../../utils/sessionStorageManager/sessionStorage';
import { useHistory } from 'react-router-dom';
import ShoeberErrorMessage from '../Core/ShoeberErrorMessage';

export default function Login() {
	const siteAttemptedWithNoLoginManager = createSessionStorage(
		SITE_ATTEMPTED_WITH_NO_LOGIN,
		'string',
		'/'
	);

	const loadLoginPage = LoadOnce();

	const passwordInput = useInput('');

	const emailInput = useInput('');

	const [loading, setLoading] = useState(false);

	const history = useHistory();

	const [redirectTo, setRedirectTo] = useState(undefined);

	const [errorMessage, setErrorMessage] = useState(undefined);

	useEffect(() => {
		if (loadLoginPage.canLoad()) {
			siteAttemptedWithNoLoginManager.addCallback(() => {
				setRedirectTo(siteAttemptedWithNoLoginManager.get());
			});
		}
	}, [loadLoginPage, siteAttemptedWithNoLoginManager]);

	function handleSubmit(event) {
		event.preventDefault();

		if (emailInput.value && passwordInput.value) {
			const accountApi = ShoeberBackendApi.getAccountApi();
			const promise = accountApi.accountLoginPost({
				email: emailInput.value,
				password: passwordInput.value,
			});
			setLoading(true);
			promise.then(
				(response) => {
					const data = response.data;
					if (
						data !== undefined &&
						data.token !== undefined &&
						data.ttl_in_millis !== undefined &&
						data.user_type !== undefined
					) {
						setLogin(data.token, data.ttl_in_millis, data.user_type);

						setLoading(false);

						setErrorMessage(undefined);

						let currentRedirectTo = redirectTo;
						siteAttemptedWithNoLoginManager.reset();

						history.push(currentRedirectTo);
					} else {
						setErrorMessage('You are unable to log in at this time.');
						setLoading(false);
						logout();
					}
				},
				(failedResponse) => {
					setErrorMessage('Email or password incorrect');
					setLoading(false);
				}
			);
		} else {
			setErrorMessage('Password and email required');
		}
	}

	return (
		<>
			<Loading loading={loading} />
			<Form style={{ paddingTop: '10px' }}>
				<img
					alt='Shoeber Dropbox'
					src='https://s3.us-east-2.amazonaws.com/shoeber.html.helpers.graffititech.co/images/static/shoeberLogoLarge.png '
					height='220'
					style={{ paddingBottom: '10px' }}
				/>
				<Row className='justify-content-md-center'>
					<h2 className='loginHeader'>Login</h2>
				</Row>
				<Row className='justify-content-md-center'>
					<Col md={6}>
						<Form.Group controlId='formBasicEmail'>
							<Form.Label>Email address</Form.Label>
							<Form.Control
								type='email'
								placeholder='Enter email'
								{...emailInput.bind}
								name='email'
							/>
						</Form.Group>
					</Col>
				</Row>
				<Row className='justify-content-md-center'>
					<Col md={6}>
						<Form.Group controlId='formBasicPassword'>
							<Form.Label>Password</Form.Label>
							<Form.Control
								type='password'
								placeholder='Password'
								{...passwordInput.bind}
								name='password'
								autoComplete='currentPassword'
							/>
						</Form.Group>
					</Col>
				</Row>

				<Row className='justify-content-md-center'>
					<Col>
						<ShoeberButton
							onClick={handleSubmit}
							text='Submit'
							clickOnEnter={true}
						/>
					</Col>
				</Row>
				{errorMessage && <ShoeberErrorMessage message={errorMessage} />}
				<ShoeberLink to='/signup' text='Create Account' />
			</Form>
		</>
	);
}
