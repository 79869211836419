import React, { Component } from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './ChoicesPage.css'

 class ChoicesPage extends Component {
  render() {
    return (
      <div>
        <h1 style={{ fontWeight: 'bolder', marginTop: '5%' }}>EXCLUSIVE SHOE & LEATHER REPAIR CENTER</h1>
        <h2>SELECT YOUR SHOEBER SERVICE</h2>
        <Link to='/home'>
        <Card style={{  marginBottom: '5%'  }}>
  <Card.Body className='choices'>
    <Card.Title as="h2">Pick Up and Delivery</Card.Title>
    <Card.Text>
      Have a Shoberina pick up repair and deliver when ready.
    </Card.Text>
  </Card.Body>
</Card>
</Link>

<Link to='/home'>
        <Card>
  <Card.Body className='choices' >
    <Card.Title as="h2">Dropbox</Card.Title>
    <Card.Text>
      Choose a local Shoeber Dropbox and when ready free delivery.
    </Card.Text>
  </Card.Body>
</Card>
</Link>
      </div>
    )
  }
}
export default ChoicesPage