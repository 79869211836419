import './ShoeberShoppingCart.css';
import LoadOnce from '../../../hooks/loadOnce';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { theme } from '../../../theme';
import {
	createLocalStorage,
	MY_REPAIR_ITEMS_NAME,
} from '../../../utils/localStorageManager/localStorage';

const StyledShoeberShoppingCart = styled.div`
	color: ${({ theme }) => theme.primaryHover};
	font-size: 20px;
	padding-top: ${({ theme }) => theme.menuHeight * 0.15 + 'px'};
`;

export default function ShoeberShoppingCart() {
	const myCartLocalStorageManager = createLocalStorage(
		MY_REPAIR_ITEMS_NAME,
		'array'
	);
	const [myCart, setMyCart] = useState([]);

	const addMyRepairItemsManager = LoadOnce();

	useEffect(() => {
		if (addMyRepairItemsManager.canLoad()) {
			myCartLocalStorageManager.addCallback(() => {
				setMyCart(myCartLocalStorageManager.get());
			});
		}
	}, [addMyRepairItemsManager, myCartLocalStorageManager]);

	return (
		<Container>
			<Row>
				<Col xs={8} style={{ paddingLeft: '0px' }}>
					<div className='icon-cart'>
						<div
							className='cart-line-1'
							style={{ backgroundColor: theme.primaryHover }}></div>
						<div
							className='cart-line-2'
							style={{ backgroundColor: theme.primaryHover }}></div>
						<div
							className='cart-line-3'
							style={{ backgroundColor: theme.primaryHover }}></div>
						<div
							className='cart-wheel'
							style={{ backgroundColor: theme.primaryHover }}></div>
					</div>
				</Col>
				<Col style={{ padding: '0px' }}>
					<StyledShoeberShoppingCart>{myCart.length}</StyledShoeberShoppingCart>
				</Col>
			</Row>
		</Container>
	);
}
