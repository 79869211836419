import React from 'react';
import LoadOnce from '../../hooks/loadOnce';
import Loading from '../Core/Loading/Loading';
import { useEffect, useState } from 'react';
import useRedirectWithAlert from '../../hooks/useRedirectWithAlert';
import { ShoeberBackendApi } from '../../api/ShoeberBackendApi';
import {
	urlParam,
	CODE_URL_PARAM_NAME,
	API_KEY_URL_PARAM_NAME,
} from '../../utils/url';

import { DANGER, SUCCESS, INFO } from '../Core/ShoeberAlert';
import { HasRequiredAccountType } from '../../hooks/confirmedAccountType';

export default function VerifyEmail() {
	const loadVerifyEmail = LoadOnce();

	const [userLoggedIn, setUserLoggedIn] = useState(false);
	HasRequiredAccountType(function () {
		setUserLoggedIn(true);
	});

	const loadSessionManagers = LoadOnce();

	const [loading, setLoading] = useState(false);

	const redirectWithAlert = useRedirectWithAlert();

	const utilsApi = ShoeberBackendApi.getUtilsApi();

	const accountApi = ShoeberBackendApi.getAccountApi();

	useEffect(() => {
		function redirectWithError() {
			redirectWithAlert.redirectWithAlert(
				'/',
				'Email could not be verified',
				'Something went wrong with your email verification, use the code sent to your email on the profile page.',
				DANGER,
				8000
			);
		}

		if (userLoggedIn && loadVerifyEmail.canLoad()) {
			setLoading(true);
			accountApi
				.meGet()
				.then((response) => {
					if (response.data.email_verified === true) {
						redirectWithAlert.redirectWithAlert(
							'/',
							'Email Verified',
							'Your email has already been verified',
							INFO
						);
					} else {
						const sentCode = urlParam(CODE_URL_PARAM_NAME);
						const sentApiKey = urlParam(API_KEY_URL_PARAM_NAME);

						if (!sentCode || !sentApiKey) {
							redirectWithError();
						}

						utilsApi
							.utilsKeyGet(sentApiKey)
							.then((response) => {
								accountApi
									.accountVerifyEmailPost({
										code: sentCode,
									})
									.then((response) => {
										redirectWithAlert.redirectWithAlert(
											'/',
											'Email verified',
											'Your email has been successfully verified',
											SUCCESS
										);
									});
							})
							.catch((errorResponse) => {
								redirectWithError();
							});
					}
				})
				.catch((errorResponse) => {
					redirectWithError();
				});
		}
	}, [
		accountApi,
		loadSessionManagers,
		loadVerifyEmail,
		redirectWithAlert,
		userLoggedIn,
		utilsApi,
	]);

	return (
		<>
			<Loading loading={loading} />
		</>
	);
}
