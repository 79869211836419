import { useState } from 'react';

export const useManagedStorageInput = (storageManager, key = undefined) => {
	const [value, setInnerValue] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	function setValue(value) {
		if (key !== undefined) {
			storageManager.set(value, key);
		} else {
			storageManager.set(value);
		}
		setInnerValue(value);
	}

	function init(defaultValue = '') {
		if (key !== undefined) {
			let currentValue = storageManager.get(key);
			if (currentValue === undefined){
				storageManager.set(defaultValue, key)
				setInnerValue(defaultValue);
			}else{
				setInnerValue(currentValue);
			}
		} else {
			setInnerValue(storageManager.get() || defaultValue);
		}
	}

	return {
		value,
		setValue,
		bind: {
			value,
			onChange: (event) => {
				setValue(event.target.value);
			},
		},
		errorMessage,
		setErrorMessage,
		init,
	};
};
