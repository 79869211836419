import {
	createSessionStorage,
	MY_WORKING_ORDER,
} from '../../../utils/sessionStorageManager/sessionStorage';
import { useManagedStorageInput } from '../../../hooks/managedStorageInput';
import LoadOnce from '../../../hooks/loadOnce';
import { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { PICKUP_ORDER } from './OrderType';
import {
	getDateTimeString,
	getDateTime,
} from '../../../utils/date';
import DateTimePicker from 'react-datetime-picker';

export default function PickupAddress({ errorMessageHash, orderType }) {
	const myWorkingOrderSessionManager = createSessionStorage(
		MY_WORKING_ORDER,
		'json'
	);

	const PICKUP_ADDRESS_ONE = 'pickup_address_one';
	const PICKUP_ADDRESS_TWO = 'pickup_address_two';
	const PICKUP_POSTAL_CODE = 'pickup_postal_code';
	const PICKUP_CITY = 'pickup_city';
	const PICKUP_STATE = 'pickup_state';
	const PICKUP_COUNTRY = 'pickup_country';
	const PICKUP_DATE_TIME = 'requested_pickup_date_time';

	const loadPickupAddressData = LoadOnce();

	const pickUpAddressOneInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		PICKUP_ADDRESS_ONE
	);

	const [pickupDateTimeObject, sePickupDateTimeObject] = useState(new Date());

	const [
		pickupDateTimeObjectCanLoad,
		setPickupDateTimeObjectCanLoad,
	] = useState(false);

	const loadPickupDateTimeObject = LoadOnce();

	const pickupDateTimeInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		PICKUP_DATE_TIME
	);

	const pickUpAddressTwoInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		PICKUP_ADDRESS_TWO
	);

	const pickUpPostalCodeInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		PICKUP_POSTAL_CODE
	);

	const pickUpCityInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		PICKUP_CITY
	);

	const pickUpStateInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		PICKUP_STATE
	);

	const pickUpCountryInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		PICKUP_COUNTRY
	);

	useEffect(() => {
		if (loadPickupAddressData.canLoad()) {
			pickUpAddressOneInput.init();
			pickUpAddressTwoInput.init();
			pickUpPostalCodeInput.init();
			pickUpCityInput.init();
			pickUpStateInput.init();
			pickUpCountryInput.init('US');
			pickupDateTimeInput.init(getDateTimeString(new Date()));
			setPickupDateTimeObjectCanLoad(true);
		}

		// The value from pickupDateTimeInput needs a completed useEffect function
		// to run before its value can be set.
		if (pickupDateTimeObjectCanLoad && loadPickupDateTimeObject.canLoad()) {
			sePickupDateTimeObject(getDateTime(pickupDateTimeInput.value));
		}
	}, [
		loadPickupAddressData,
		myWorkingOrderSessionManager,
		pickUpAddressOneInput,
		pickUpAddressTwoInput,
		pickUpPostalCodeInput,
		pickUpCityInput,
		pickUpStateInput,
		pickUpCountryInput,
		pickupDateTimeObjectCanLoad,
		loadPickupDateTimeObject,
		pickupDateTimeInput,
	]);

	return (
		<>
			{orderType === PICKUP_ORDER && (
				<>
					<h3>Pickup Address</h3>
					<Row>
						<Col md>
							<Form.Group>
								<Form.Label>Pickup Address Line One</Form.Label>
								<Form.Control
									type='text'
									{...pickUpAddressOneInput.bind}
									placeholder='Pickup Address Line One'
									required
									isInvalid={errorMessageHash[PICKUP_ADDRESS_ONE]}
								/>
								<Form.Control.Feedback type='invalid'>
									{errorMessageHash[PICKUP_ADDRESS_ONE]}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col md>
							<Form.Group>
								<Form.Label>Pickup Address Line Two</Form.Label>
								<Form.Control
									type='text'
									{...pickUpAddressTwoInput.bind}
									placeholder='Pickup Address Line Two'
									isInvalid={errorMessageHash[PICKUP_ADDRESS_TWO]}
								/>
								<Form.Control.Feedback type='invalid'>
									{errorMessageHash[PICKUP_ADDRESS_TWO]}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm>
							<Form.Group>
								<Form.Label>Pickup Postal Code</Form.Label>
								<Form.Control
									type='text'
									{...pickUpPostalCodeInput.bind}
									placeholder='Pickup Postal Code'
									required
									isInvalid={errorMessageHash[PICKUP_POSTAL_CODE]}
								/>
								<Form.Control.Feedback type='invalid'>
									{errorMessageHash[PICKUP_POSTAL_CODE]}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm>
							<Form.Group>
								<Form.Label>Pickup City</Form.Label>
								<Form.Control
									type='text'
									{...pickUpCityInput.bind}
									placeholder='Pickup City'
									isInvalid={errorMessageHash[PICKUP_CITY]}
								/>
								<Form.Control.Feedback type='invalid'>
									{errorMessageHash[PICKUP_CITY]}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm>
							<Form.Group>
								<Form.Label>Pickup State</Form.Label>
								<Form.Control
									type='text'
									{...pickUpStateInput.bind}
									placeholder='Pickup State'
									isInvalid={errorMessageHash[PICKUP_STATE]}
								/>
								<Form.Control.Feedback type='invalid'>
									{errorMessageHash[PICKUP_STATE]}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm>
							<Form.Group>
								<Form.Label>Pickup Country</Form.Label>
								<Form.Control
									type='text'
									{...pickUpCountryInput.bind}
									isInvalid={errorMessageHash[PICKUP_STATE]}
									placeholder='Pickup Country'
								/>
								<Form.Control.Feedback type='invalid'>
									{errorMessageHash[PICKUP_COUNTRY]}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={7}>
							<Form.Group>
								<Form.Label>Dropoff Time and Date</Form.Label>
								<Form.Control
									as={DateTimePicker}
									onChange={(value) => {
										sePickupDateTimeObject(value);
										pickupDateTimeInput.setValue(getDateTimeString(value));
									}}
									value={pickupDateTimeObject}
									isInvalid={errorMessageHash[PICKUP_DATE_TIME]}
								/>
								<Form.Control.Feedback type='invalid'>
									{errorMessageHash[PICKUP_DATE_TIME]}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
				</>
			)}
		</>
	);
}
