import styled from 'styled-components';

export const StyledRunningReceipt = styled.div`
	display: flex;
	flex-direction: column;
	color:  ${({ theme }) => theme.black};
	background: ${({ theme }) => theme.primaryLight};
	border: 2px solid black;
	padding: 2rem;
	position: fixed;
	top: ${({ theme }) => theme.menuHeight - 1 + 'px'};
	right: 0;
	transition: transform 0.3s ease-in-out;
	min-width: ${(props) => (props.mobile ? '80%' : '50%')};
	max-width: ${(props) => (props.mobile ? '100%' : '75%')};
	z-index: 8;
	min-height 700px;

	max-height: 100%;
	transform: translateX(100%);
	transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
	overflow-y:auto;
`;
