import React, { Component } from 'react'
import {Image } from 'react-bootstrap'
import './LandingPage.css'
import { Link } from 'react-router-dom'
import skyline from '../../Images/146.jpg'

class LandingPage extends Component {
  render() {
    return (
      <div>
        <h2 style={{color: "#ff8c00"}}>GIVE A SECOND CHANCE TO YOUR FAVORITE PAIR</h2>
        <Image src={skyline} fluid />
        <br/>
        <Link to='/choice'><button className="button" style={{ marginTop: '2%' }}>
      Start Your Repair
    </button>{' '}</Link>
      </div>
    )
  }
}
export default LandingPage