import AccountPayment, {
	CARD_NUMBER,
	CARD_NAME,
	CARD_EXPIRY,
	CARD_CVC,
} from '../../AccountPayment/AccountPayment';
import { Modal, Row, Col, Form } from 'react-bootstrap';
import ShoeberButton from '../../Core/ShoeberButton';
import ModalCloseButton from '../../Core/Modal/ModalCloseButton';
import ModalBackButton from '../../Core/Modal/ModalBackButton';
import useModal from '../../../hooks/useModal';
import { useEffect } from 'react';
import ShoeberLine from '../../Core/ShoeberLine';
import {
	createSessionStorage,
	MY_WORKING_ORDER,
} from '../../../utils/sessionStorageManager/sessionStorage';
import { useManagedStorageInput } from '../../../hooks/managedStorageInput';
import LoadOnce from '../../../hooks/loadOnce';
import BillingAddress from './BillingAddress';

export const CARD_INFO = 'card_info';
export const BILLING_ADDRESS_SAME_AS_DROPOFF =
	'billing_address_equals_dropoff_address';

export default function AccountPaymentInput({
	saveCardData,
	errorMessageHash,
	cardData,
}) {
	const cardInfoModal = useModal();

	const myWorkingOrderSessionManager = createSessionStorage(
		MY_WORKING_ORDER,
		'json'
	);

	const billingAddressSameAsDropOffAddress = useManagedStorageInput(
		myWorkingOrderSessionManager,
		BILLING_ADDRESS_SAME_AS_DROPOFF
	);

	const addWorkingOrderSessionManager = LoadOnce();

	useEffect(() => {
		if (addWorkingOrderSessionManager.canLoad()) {
			let currentBillingAddressSameAsDropoffAddress = myWorkingOrderSessionManager.get(
				BILLING_ADDRESS_SAME_AS_DROPOFF
			);

			if (currentBillingAddressSameAsDropoffAddress === undefined) {
				currentBillingAddressSameAsDropoffAddress = true;
			}

			billingAddressSameAsDropOffAddress.setValue(
				currentBillingAddressSameAsDropoffAddress
			);
		}
	}, [
		addWorkingOrderSessionManager,
		billingAddressSameAsDropOffAddress,
		myWorkingOrderSessionManager,
	]);

	function secureCardNumber(number) {
		if (!number) {
			return 'None';
		} else {
			let numberArray = number.split(' ');
			numberArray.forEach((cardSegment, index) => {
				if (index + 1 < numberArray.length) {
					numberArray[index] = '*'.repeat(numberArray[index].length);
				}
			});
			return numberArray.join(' ');
		}
	}

	function secureExpire(expire) {
		return expire && expire.length === 5 ? '**/**' : 'None';
	}

	function secureCVC(cvc) {
		return cvc ? '*'.repeat(cvc.length) : 'None';
	}

	function handleSaveCardData(cardInfo) {
		cardInfoModal.toggle();
		saveCardData(cardInfo);
	}

	return (
		<>
			<Row style={{ paddingTop: '8px' }}>
				<Col>
					<h3 md={9}>Payment Info</h3>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group style={{ marginTop: '8px' }}>
						<Form.Check
							type='checkbox'
							label='Billing address dropoff address'
							onChange={() => {
								billingAddressSameAsDropOffAddress.setValue(
									!billingAddressSameAsDropOffAddress.value
								);
							}}
							checked={billingAddressSameAsDropOffAddress.value}
						/>
					</Form.Group>
				</Col>
				<Col>
					<ShoeberButton text='Update' onClick={cardInfoModal.toggle} />
				</Col>
			</Row>
			<Row>
				<Col sm>
					<Row>
						<Col>Name: {cardData[CARD_NAME] || 'None'}</Col>
					</Row>
					{errorMessageHash[CARD_NAME] && (
						<Row>
							<Col>{errorMessageHash[CARD_NAME]}</Col>
						</Row>
					)}
				</Col>
				<Col sm>
					<Row>
						<Col>Number: {secureCardNumber(cardData[CARD_NUMBER])}</Col>
					</Row>
					{errorMessageHash[CARD_NUMBER] && (
						<Row>
							<Col>{errorMessageHash[CARD_NUMBER]}</Col>
						</Row>
					)}
				</Col>
				<Col sm>
					<Row>
						<Col>Expire: {secureExpire(cardData[CARD_EXPIRY])}</Col>
					</Row>
					{errorMessageHash[CARD_EXPIRY] && (
						<Row>
							<Col>{errorMessageHash[CARD_EXPIRY]}</Col>
						</Row>
					)}
				</Col>
				<Col sm>
					<Row>
						<Col>CVC: {secureCVC(cardData[CARD_CVC])}</Col>
					</Row>
					{errorMessageHash[CARD_CVC] && (
						<Row>
							<Col>{errorMessageHash[CARD_CVC]}</Col>
						</Row>
					)}
				</Col>
			</Row>
			<>
				{!billingAddressSameAsDropOffAddress.value && (
					<>
						<ShoeberLine />
						<BillingAddress errorMessageHash={errorMessageHash} />
					</>
				)}
			</>

			<Modal
				show={cardInfoModal.isShowing}
				onHide={cardInfoModal.toggle}
				animation={false}>
				<Modal.Header>
					<ModalBackButton onClick={cardInfoModal.toggle} />
					<Modal.Title>Payment Info</Modal.Title>
					<ModalCloseButton onClick={cardInfoModal.toggle} />
				</Modal.Header>
				<Modal.Body>
					<AccountPayment
						saveCardData={handleSaveCardData}
						errorMessageHash={errorMessageHash}
						cardData={cardData}
					/>
				</Modal.Body>
			</Modal>
		</>
	);
}
