import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-credit-cards/es/styles-compiled.css';
import Navigation from './Components/Navigation';
import Home from './Components/Home/Home';
import LocationMap from './Components/LocationMap';
import Signup from './Components/Signup/Signup';
import { GlobalStyles } from './global';
import { withRouter, Switch, Redirect } from 'react-router-dom';
import ShoeberHelmet from './Components/ShoeberHelmet/ShoeberHelmet';
import { theme } from './theme';
import { ThemeProvider } from 'styled-components';
import { Container } from 'react-bootstrap';
import { HelmetProvider } from 'react-helmet-async';
import Login from './Components/Login/Login';
import Profile from './Components/Profile';
import { validTokenActive } from './hooks/confirmedAccountType';
import Checkout from './Components/Checkout';
import ShoeberPageNotFound from './Components/Core/ShoeberPageNotFound';
import AlertManager from './Components/Core/AlertManager';
import VerifyEmail from './Components/VerifyEmail';
import MyOrders from './Components/MyOrders';
import LandingPage from './Components/LandingPage/LandingPage'
import ChoicesPage from './Components/ChoicesPage/ChoicesPage'

function App() {
	validTokenActive();

	const [openRunningReceipt, setOpenRunningReceipt] = useState(false);
	const [activeEditItem, setActiveEditItem] = useState(undefined);

	return (
		<HelmetProvider>
			<ThemeProvider theme={theme}>
				<GlobalStyles />
				<ShoeberHelmet />
				<AlertManager />
				<Navigation
					openRunningReceipt={openRunningReceipt}
					setOpenRunningReceipt={setOpenRunningReceipt}
					setActiveEditItem={setActiveEditItem}
				/>
				<Container className='App'>
					<Switch>
						<Route exact path='/login' component={Login} />
						<Route exact path='/locations' component={LocationMap} />
						<Route exact path='/signup' component={Signup} />
						<Route exact path='/profile' component={Profile} />
						<Route exact path='/' component={LandingPage} />
						<Route exact path='/choice' component={ChoicesPage} />
						<Route exact path='/home'>
							<Home
								setOpenRunningReceipt={setOpenRunningReceipt}
								activeEditItem={activeEditItem}
								setActiveEditItem={setActiveEditItem}
							/>
						</Route>
						<Route path='/checkout'>
							<Checkout setActiveEditItem={setActiveEditItem} />
						</Route>
						<Route path='/verify/email' component={VerifyEmail} />
						<Route path='/my/orders' component={MyOrders} />
						<Route path={'/404'} component={ShoeberPageNotFound} />
						<Redirect to='/404' />
					</Switch>
				</Container>
			</ThemeProvider>
		</HelmetProvider>
	);
}

export default withRouter(App);
