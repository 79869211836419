import { Link } from 'react-router-dom';
import React from 'react';
import { theme } from '../../theme';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {Button} from 'react-bootstrap';

export const VARIANT_LIGHT = 'light';

export default function ShoeberLink({
	to = null,
	disabled = false,
	style = {},
	text = 'Here',
	variant = null,
	className = '',
	onClick = null,
}) {
	function getColor() {
		if (disabled) {
			return theme.secondaryDark;
		} else if (variant === VARIANT_LIGHT) {
			return theme.primaryLight;
		} else {
			return theme.primaryDark;
		}
	}

	function getHoverColor() {
		if (disabled) {
			return theme.secondaryDark;
		} else {
			return theme.primaryHover;
		}
	}

	const [color, setColor] = useState(getColor());

	if (to != null) {
		to = to.substring(0, 3) === '/#/' ? to.substring(3) : to;
	}

	const history = useHistory();

	function handleClick() {
		if (disabled !== true) {
			if (to != null) {
				if (onClick != null) {
					onClick();
				}

				history.push(to);
			} else {
				if (onClick != null) {
					onClick();
				} else {
					console.error('Shoeber link on click and to not set.');
				}
			}
		}
	}

	return (
		<>
			{to && (
				<Link
					style={Object.assign({}, style, {
						color: color,
						textDecoration: 'underline'
					})}
					disabled={disabled}
					className={className}
					onMouseEnter={() => {
						setColor(getHoverColor());
					}}
					onMouseLeave={() => {
						setColor(getColor());
					}}
					to={to}
					onClick={handleClick}>
					{text}
				</Link>
			)}
			{!to && (
				<Button
					style={Object.assign({}, {
						color: color,
						backgroundColor: 'transparent',
						border: 'none',
						textDecoration: 'underline',
						padding:'0px 10px 0px 4px',
						textAlign: 'start',
						verticalAlign: 'top'
					}, style)}
					disabled={disabled}
					
					className={className}
					onMouseEnter={() => {
						setColor(getHoverColor());
					}}
					onMouseLeave={() => {
						setColor(getColor());
					}}
					onClick={handleClick}>
					{text}
				</Button>
			)}
		</>
	);
}
