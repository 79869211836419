export function display(string) {
	if (string === undefined || string === null || typeof string !== 'string') {
		return string;
	}
	const DO_NOT_CAPITALIZE = ['a', 'of', 'the', 'is'];
	let words = string.toLowerCase().split(' ');
	for (let i = 0; i < words.length; i++) {
		let word = words[i];
		if (i === 0 || DO_NOT_CAPITALIZE.includes(word) === false) {
			if (word.length > 1) {
				words[i] = word[0].toUpperCase() + word.substring(1);
			} else {
				words[i] = word.toUpperCase();
			}
		}
	}
	return words.join(' ');
}
