import styled from 'styled-components';

export const StyledTypeSelect = styled.div`

	img {
		max-width: 100%;
		margin-top: 8px;
		border: 2px solid;
		border-color: ${({ theme }) => theme.orange}
	}

	color: ${({ theme }) => theme.primaryLight};

	&:hover {
		transform: scale(1.008);
	}
`;

const Button = styled.button`
	color: ${({ theme }) => theme.primaryDark};
	border-color: ${({ theme }) => theme.orange};
`;

export const StyledTypeSelectButton = styled(Button)`
	background: ${({ theme }) => theme.primaryLight};
	border-color: ${({ theme }) => theme.orange};

	&:hover {
		color: ${({ theme }) => theme.primaryHover};
		background: ${({ theme }) => theme.grayFour};
		border-color: ${({ theme }) => theme.orange};
	}

	&:focus {
		outline-color: ${({ theme }) => theme.primaryDark};
	}

	&:active {
		background: ${({ theme }) => theme.primaryDark};
		border-color: ${({ theme }) => theme.primaryDark};
	}
`;
