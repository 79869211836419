import React from 'react';
import { StyledCloseButton } from './Modal.styled';

export default class ModalCloseButton extends React.Component {
	render() {
		return (
			<>
				{this.props.disabled === undefined && (
					<StyledCloseButton onClick={this.props.onClick}>
						&times;
					</StyledCloseButton>
				)}
				{this.props.disabled === true &&
					this.props.allowClickOnDisabled !== true && (
						<StyledCloseButton disabled>&times;</StyledCloseButton>
					)}
				{this.props.disabled === true &&
					this.props.allowClickOnDisabled === true && (
						<StyledCloseButton falseDisabled onClick={this.props.onClick}>
							&times;
						</StyledCloseButton>
					)}
			</>
		);
	}
}
