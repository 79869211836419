import React, { useEffect, useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import { Container } from 'react-bootstrap';
import LocationMarker from '../Core/LocationMarker';
import { ShoeberBackendApi } from '../../api/ShoeberBackendApi';
import { format } from 'react-string-format';
import LocationModal from './LocationModal';
import LoadOnce from '../../hooks/loadOnce';
import useModal from '../../hooks/useModal';

function LocationMap() {
	const [locations, setLocations] = useState([]);
	const [locationKey, setLocationKey] = useState(0);

	const [locationModalInfo, setLocationModalInfo] = useState({});

	const locationViewModal = useModal();
	const modalRef = useRef();

	const defaultMapInformation = {
		center: {
			lat: 34.0755429,
			lng: -118.3756987,
		},
		zoom: 10,
	};

	const loadMapPage = LoadOnce();

	useEffect(() => {
		if (loadMapPage.canLoad()) {
			ShoeberBackendApi.getDropboxApi()
				.dropboxGet()
				.then(
					(response) => {
						let newLocations = locations;
						response.data.forEach((location) => {
							newLocations.push(location);
						});
						setLocations(newLocations);
						setLocationKey(newLocations.length);
					},
					(failedResponse) => {
						console.error('Drop box information could not be retrieved');
					}

				);

			// Load locations here
		}
	}, [loadMapPage, locations]);

	return (
		// Important! Always set the container height explicitly
		<Container>
			<h3>Shoeber Locations</h3>
			<div style={{ height: '500px', width: '100%' }}>
				<GoogleMapReact
					bootstrapURLKeys={{
						key: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY,
					}}
					key={locationKey}
					defaultCenter={defaultMapInformation.center}
					defaultZoom={defaultMapInformation.zoom}>
					{locations.map((locationMarker, index) => {
						let lat = locationMarker.location.geocode.lat;
						let lng = locationMarker.location.geocode.lng;
						return (
							<LocationMarker
								lat={lat}
								lng={lng}
								key={format('{lat:{0}-lng:{1}-{2}', lat, lng, index)}
								info={locationMarker}
								setLocationModalInfo={setLocationModalInfo}
								toggle={locationViewModal.toggle}
							/>
						);
					})}
				</GoogleMapReact>
			</div>
			<div ref={modalRef}>
				<LocationModal
					isShowing={locationViewModal.isShowing}
					toggle={locationViewModal.toggle}
					info={locationModalInfo}
				/>
			</div>
		</Container>
	);
}

export default LocationMap;
