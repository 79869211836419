import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Row, Modal, Col } from 'react-bootstrap';
import ShoeberButton from '../Core/ShoeberButton';
import { ShoeberBackendApi } from '../../api/ShoeberBackendApi';
import { useInput } from '../../hooks/input';
import ShoeberErrorMessage from '../Core/ShoeberErrorMessage';
import { HasRequiredAccountType } from '../../hooks/confirmedAccountType';
import LoadOnce from '../../hooks/loadOnce';
import Errors from '../../utils/Errors';
import { SUCCESS } from '../Core/ShoeberAlert';
import useAlert from '../../hooks/useAlert';
import useRedirectWithAlert from '../../hooks/useRedirectWithAlert';
import useClearUrlParams from '../../hooks/useClearUrlParams';
import { urlParam } from '../../utils/url';
import './Profile.css';
import useModal from '../../hooks/useModal';
import ShoeberLink from '../Core/ShoeberLink';
import ModalCloseButton from '../Core/Modal/ModalCloseButton';
import ModalBackButton from '../Core/Modal/ModalBackButton';
import {
	formatPhoneNumberDisplay,
	formatPhoneNumber,
} from '../../utils/phoneNumber';
import Loading from '../Core/Loading/Loading';

export const VERIFY_URL_PARAM = 'verify';
export const EMAIL_VERIFY_MODAL_TYPE = 'email';
export const PHONE_NUMBER_VERIFY_MODAL_TYPE = 'phone_number';

function Profile() {
	const accountApi = ShoeberBackendApi.getAccountApi();

	const loadProfile = LoadOnce();

	const [loggedIn, setLoggedIn] = useState(false);
	HasRequiredAccountType(function () {
		setLoggedIn(true);
	});

	const [loading, setIsLoading] = useState(false);

	const alert = useAlert();

	const clearUrlParams = useClearUrlParams();

	const redirectWithAlert = useRedirectWithAlert();

	const [modalSetting, setModalSetting] = useState(undefined);

	const [errorMessage, setErrorMessage] = useState(undefined);

	const {
		isShowing: verifyModalIsShowing,
		toggle: verifyModalToggle,
	} = useModal();

	const {
		value: first_name,
		bind: bindFirstName,
		setValue: setFirstName,
	} = useInput('');
	const {
		value: last_name,
		bind: bindLastName,
		setValue: setLastName,
	} = useInput('');

	const {
		value: email,
		bind: bindEmail,
		setValue: setEmail,
		errorMessage: emailErrorMessage,
		setErrorMessage: setEmailErrorMessage,
	} = useInput('');

	const { value: email_verified, setValue: setEmailVerified } = useInput(true);

	const {
		value: phone_number,
		bind: bindPhoneNumber,
		setValue: setPhoneNumber,
		errorMessage: phoneNumberErrorMessage,
		setErrorMessage: setPhoneNumberErrorMessage,
	} = useInput('');

	const verificationCodeInput = useInput('');

	const {
		value: phone_number_verified,
		setValue: setPhoneNumberVerified,
	} = useInput(true);

	function handleSubmit(evt) {
		setIsLoading(true);
		accountApi
			.mePut({
				first_name: first_name,
				last_name: last_name,
				email: email,
				phone_number: formatPhoneNumber(phone_number),
			})
			.then((response) => {
				setIsLoading(false);
				redirectWithAlert.redirectWithAlert(
					'/',
					'Success',
					'Your profile has been updated.',
					SUCCESS
				);
			})
			.catch((errorResponse) => {
				setIsLoading(false);
				let errors = new Errors(errorResponse);
				setPhoneNumberErrorMessage(errors.errorMessage('phone_number'));
				setEmailErrorMessage(errors.errorMessage('email'));
			});
	}

	const handleSendPhoneNumberVerificationCode = () => {
		accountApi.accountResendVerifyPhonePost().catch((errorResponse) => {
			let errors = new Errors(errorResponse);
			verificationCodeInput.setErrorMessage(errors.errorMessage('detail'));
		});
	};

	const handleSendEmailVerificationLink = () => {
		accountApi.accountResendVerifyEmailPost().catch((errorResponse) => {
			let errors = new Errors(errorResponse);
			verificationCodeInput.setErrorMessage(errors.errorMessage('detail'));
		});
	};

	function handleSubmitPhoneNumberVerificationCode() {
		accountApi
			.accountVerifyPhonePost({
				code: verificationCodeInput.value,
			})
			.then((response) => {
				setPhoneNumberVerified(true);
				verifyModalToggle();
				alert.sendAlert(
					'Phone Number verified',
					'Your phone number has been successfully verified',
					SUCCESS
				);
				clearUrlParams.clearIfExists(VERIFY_URL_PARAM);
			})
			.catch((errorResponse) => {
				let errors = new Errors(errorResponse);
				verificationCodeInput.setErrorMessage(errors.errorMessage('code'));
			});
	}

	function handleSubmitEmailVerificationCode() {
		accountApi
			.accountVerifyEmailPost({
				code: verificationCodeInput.value,
			})
			.then((response) => {
				setEmailVerified(true);
				verifyModalToggle();
				alert.sendAlert(
					'Email verified',
					'Your email has been successfully verified',
					SUCCESS
				);
				clearUrlParams.clearIfExists(VERIFY_URL_PARAM);
			})
			.catch((errorResponse) => {
				let errors = new Errors(errorResponse);
				verificationCodeInput.setErrorMessage(errors.errorMessage('code'));
			});
	}

	function formatVerifyCode(value) {
		if (!value) {
			return value;
		}

		value = value.replace(/\D+/g, '');

		return value.trim().substring(0, 6);
	}

	useEffect(() => {
		if (loggedIn && loadProfile.canLoad()) {
			setIsLoading(true);
			accountApi
				.meGet()
				.then((promise) => {
					const me = promise.data;
					setFirstName(me.first_name);
					setLastName(me.last_name);
					setEmail(me.email);
					setEmailVerified(me.email_verified);
					setPhoneNumber(formatPhoneNumberDisplay(me.phone_number));
					setPhoneNumberVerified(me.phone_number_verified);
					setIsLoading(false);

					const verifyAttempt = urlParam(VERIFY_URL_PARAM);
					if (
						verifyAttempt === EMAIL_VERIFY_MODAL_TYPE &&
						me.email_verified === false
					) {
						setModalSetting(EMAIL_VERIFY_MODAL_TYPE);
						verifyModalToggle();
					} else if (
						verifyAttempt === PHONE_NUMBER_VERIFY_MODAL_TYPE &&
						me.phone_number_verified === false
					) {
						setModalSetting(PHONE_NUMBER_VERIFY_MODAL_TYPE);
						verifyModalToggle();
					}
				})
				.catch((errorResponse) => {
					setIsLoading(false);
					setErrorMessage(
						'Error getting profile info. Please try again later.'
					);
				});
		}
	}, [
		accountApi,
		loadProfile,
		loggedIn,
		setEmail,
		setEmailVerified,
		setFirstName,
		setLastName,
		setPhoneNumber,
		setPhoneNumberVerified,
		verifyModalToggle,
	]);

	return (
		<>
			<Loading loading={loading} />
			<Form style={{ paddingTop: '10px' }}>
				<Row>
					<h2 className='profileHeader'>My profile</h2>
				</Row>
				<Form.Group>
					<Form.Label>First Name</Form.Label>
					<Form.Control
						type='text'
						{...bindFirstName}
						placeholder='First name'
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>Last Name</Form.Label>
					<Form.Control type='text' {...bindLastName} placeholder='Last name' />
				</Form.Group>
				<Form.Group>
					<Form.Label>Phone Number</Form.Label>
					<Form.Control
						type='text'
						{...bindPhoneNumber}
						placeholder='Phone Number'
						onChange={(event) =>
							setPhoneNumber(formatPhoneNumberDisplay(event.target.value))
						}
						isInvalid={phoneNumberErrorMessage}
					/>
					{phoneNumberErrorMessage && (
						<ShoeberErrorMessage message={phoneNumberErrorMessage} />
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>Email</Form.Label>
					<Form.Control
						type='text'
						{...bindEmail}
						placeholder='Email'
						isInvalid={emailErrorMessage}
					/>
					<Form.Control.Feedback type='invalid'>
						{emailErrorMessage}
					</Form.Control.Feedback>
				</Form.Group>
				<div style={{ marginBottom: '10px' }}>
					<ShoeberButton
						text='Update'
						onClick={handleSubmit}
						clickOnEnter={!verifyModalIsShowing}
					/>
					{errorMessage && <ShoeberErrorMessage message={errorMessage} />}
				</div>
				{(!email_verified || !phone_number_verified) && (
					<Row>
						{!email_verified && (
							<Col sm={6}>
								<Row>
									Need email verification link? Click:
									<ShoeberLink
										text=' here'
										onClick={handleSendEmailVerificationLink}
									/>
								</Row>
								<Row>
									Have a email code? Click:
									<ShoeberLink
										text=' here'
										onClick={() => {
											setModalSetting(EMAIL_VERIFY_MODAL_TYPE);
											verifyModalToggle();
										}}
									/>
								</Row>
							</Col>
						)}
						{!phone_number_verified && (
							<Col sm={6}>
								<Row>
									Send SMS verification code click:
									<ShoeberLink
										text=' here'
										onClick={handleSendPhoneNumberVerificationCode}
									/>
								</Row>
								<Row>
									Have a SMS code? Click:
									<ShoeberLink
										text=' here'
										onClick={() => {
											setModalSetting(PHONE_NUMBER_VERIFY_MODAL_TYPE);
											verifyModalToggle();
										}}
									/>
								</Row>
							</Col>
						)}
					</Row>
				)}
			</Form>
			<Modal
				show={verifyModalIsShowing}
				onHide={verifyModalToggle}
				animation={false}>
				<Modal.Header>
					<ModalBackButton onClick={verifyModalToggle} />
					<Modal.Title>
						Verify{' '}
						{modalSetting === EMAIL_VERIFY_MODAL_TYPE
							? 'Email'
							: 'Phone Number'}
					</Modal.Title>
					<ModalCloseButton onClick={verifyModalToggle} />
				</Modal.Header>
				<Modal.Body>
					<Form inline>
						<Form.Label style={{ paddingRight: '10px' }} htmlFor='verify_code'>
							Code{' '}
						</Form.Label>
						<Form.Control
							type='text'
							{...verificationCodeInput.bind}
							placeholder={
								modalSetting === EMAIL_VERIFY_MODAL_TYPE
									? 'Email Code'
									: 'Phone Number Code'
							}
							id='verify_code'
							onChange={(event) =>
								verificationCodeInput.setValue(
									formatVerifyCode(event.target.value)
								)
							}
							isInvalid={verificationCodeInput.errorMessage}
						/>
						<Form.Control.Feedback type='invalid'>
							{verificationCodeInput.errorMessage}
						</Form.Control.Feedback>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<ShoeberButton
						disabled={
							!verificationCodeInput.value ||
							verificationCodeInput.value.length < 6
						}
						clickOnEnter={verifyModalIsShowing}
						onClick={() => {
							if (modalSetting === EMAIL_VERIFY_MODAL_TYPE) {
								handleSubmitEmailVerificationCode();
							} else {
								handleSubmitPhoneNumberVerificationCode();
							}
						}}
						text='Submit'
					/>
					<ShoeberButton onClick={verifyModalToggle} secondary text='Close' />
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default withRouter(Profile);
