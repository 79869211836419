import { Alert, Button } from 'react-bootstrap';
import { display } from '../../utils/display';
import styled from 'styled-components';
import { useScreenType } from '../../hooks/useScreenType';
import { useEffect } from 'react';

const StyledAlert = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 11;
`;

export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
export const SUCCESS = 'success';
export const DANGER = 'danger';
export const WARNING = 'warning';
export const INFO = 'info';
export const LIGHT = 'light';
export const DARK = 'dark';

function ShoeberAlert({
	variant,
	title,
	message,
	show,
	setShow,
	timeout = 4000,
}) {
	const screenType = useScreenType();

	let width = screenType.isMobile
		? screenType.screenWidth
		: screenType.screenWidth * 0.5;
	let marginLeft = screenType.isMobile ? 0 : screenType.screenWidth * 0.25;

	useEffect(() => {
		if (show === true) {
			setTimeout(() => {
				setShow(false);
			}, timeout);
		}
	}, [setShow, show, timeout]);

	return (
		<StyledAlert style={{ width: width, marginLeft: marginLeft }}>
			<Alert show={show} variant={variant}>
				<Alert.Heading>{display(title)}</Alert.Heading>
				{message}
				<hr />
				<div className='d-flex justify-content-end'>
					<Button onClick={() => setShow(false)} variant='outline-{variant}'>
						Close
					</Button>
				</div>
			</Alert>
		</StyledAlert>
	);
}

export default ShoeberAlert;
