import styled from 'styled-components';

export const StyledCloseButton = styled.button`
	color: ${(props) =>
		props.disabled || props.falseDisabled
			? ({ theme }) => theme.secondaryDark
			: ({ theme }) => theme.primaryDark};
	float: right;
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
	background-color: transparent;
	border: none;

	&:focus {
		outline: none;
	}

	&:hover {
		color: ${(props) =>
			props.disabled
				? ({ theme }) => theme.secondaryDark
				: props.falseDisabled
				? ({ theme }) => theme.errorPrimary
				: ({ theme }) => theme.primaryHover};
		transform: ${(props) =>
			props.disabled || props.falseDisabled ? 'scale(1);' : 'scale(1.3);'};

		cursor: ${(props) =>
			props.disabled || props.falseDisabled ? 'default' : 'pointer'};
	}
`;

export const StyledModalBackButton = styled.button`
	color: ${(props) =>
		props.disabled || props.falseDisabled
			? ({ theme }) => theme.secondaryDark
			: ({ theme }) => theme.primaryDark};
	float: left;
	font-size: 1.5rem;
	font-weight: bold;
	line-height: 1;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
	background-color: transparent;
	border: none;
	transform: scaleX(-1);

	&:focus {
		outline: none;
	}

	&:hover {
		color: ${(props) =>
			props.disabled
				? ({ theme }) => theme.secondaryDark
				: props.falseDisabled
				? ({ theme }) => theme.errorPrimary
				: ({ theme }) => theme.primaryHover};
		transform: ${(props) =>
			props.disabled || props.falseDisabled ? 'scaleX(-1);' : 'scale(-1.3);'};
		cursor: ${(props) =>
			props.disabled || props.falseDisabled ? 'default' : 'pointer'};
	}
`;
