import ReactLoading from 'react-loading';
import { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { LoadingOverlay } from './Loading.styled';
import { theme } from '../../../theme';
import './Loading.css';
import {makeId} from '../../../utils/id'

class Loading extends Component {
	constructor() {
		super();
		this.state = {
			myLoadingId: makeId()
		};
	}

	render() {
		let thisIsRendering =
			document.getElementById(this.state.myLoadingId) !== null;
		let anyIsLoading = document.getElementById('loadingContainer') !== null;
		if (
			(this.props.loading === true && anyIsLoading === false) ||
			(this.props.loading === true &&
				anyIsLoading === true &&
				thisIsRendering === true)
		) {
			return (
				<Container id='loadingContainer'>
					<LoadingOverlay />
					<Row className='justify-content-xs-center'>
						<Col xs={2} md={4}></Col>
						<Col
							xs={4}
							md={4}
							style={{ paddingLeft: '6%' }}
							id={this.state.myLoadingId}>
							<ReactLoading
								type='cubes'
								color={theme.primaryHover}
								width='200px'
								className='loading'
							/>
						</Col>
						<Col xs={1} md={4}></Col>
					</Row>
				</Container>
			);
		} else {
			return '';
		}
	}
}

export default Loading;
