import {
	createSessionStorage,
	ALERT_INFO,
} from '../utils/sessionStorageManager/sessionStorage';
import { SECONDARY } from '../Components/Core/ShoeberAlert';

export default function useAlert() {
	const alertInfoSessionStorage = createSessionStorage(ALERT_INFO, 'json');

	function sendAlert(
		title,
		message,
		variant = SECONDARY,
		timeout = 4000
	) {
		alertInfoSessionStorage.set({
			display: true,
			title: title,
			message: message,
			timeout: timeout,
			variant: variant,
		});

	}

	return {
		sendAlert,
	};
}
