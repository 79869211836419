import {
	createSessionStorage,
	MY_WORKING_ORDER,
} from '../../../utils/sessionStorageManager/sessionStorage';
import { useManagedStorageInput } from '../../../hooks/managedStorageInput';
import LoadOnce from '../../../hooks/loadOnce';
import { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import { getDateTimeString, getDateTime } from '../../../utils/date';

export default function DropoffAddress({ errorMessageHash }) {
	const myWorkingOrderSessionManager = createSessionStorage(
		MY_WORKING_ORDER,
		'json'
	);

	const DROPOFF_ADDRESS_ONE = 'dropoff_address_one';
	const DROPOFF_ADDRESS_TWO = 'dropoff_address_two';
	const DROPOFF_POSTAL_CODE = 'dropoff_postal_code';
	const DROPOFF_CITY = 'dropoff_city';
	const DROPOFF_STATE = 'dropoff_state';
	const DROPOFF_COUNTRY = 'dropoff_country';
	const DROPOFF_DATE_TIME = 'requested_dropoff_date_time';

	const [dropoffDateTimeDisplay, setDropOffDateTimeObject] = useState(
		new Date()
	);

	const loadDropoffAddressData = LoadOnce();

	const [
		dropoffDateTimeObjectCanLoad,
		setDropOffDateTimeObjectCanLoad,
	] = useState(false);

	const loadDropoffDateTimeObject = LoadOnce();

	const dropoffDateTimeInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		DROPOFF_DATE_TIME
	);

	const dropOffAddressOneInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		DROPOFF_ADDRESS_ONE
	);

	const dropOffAddressTwoInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		DROPOFF_ADDRESS_TWO
	);

	const dropOffPostalCodeInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		DROPOFF_POSTAL_CODE
	);

	const dropOffCityInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		DROPOFF_CITY
	);

	const dropOffStateInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		DROPOFF_STATE
	);

	const dropOffCountryInput = useManagedStorageInput(
		myWorkingOrderSessionManager,
		DROPOFF_COUNTRY
	);

	useEffect(() => {
		if (loadDropoffAddressData.canLoad()) {
			dropOffAddressOneInput.init();
			dropOffAddressTwoInput.init();
			dropOffPostalCodeInput.init();
			dropOffCityInput.init();
			dropOffStateInput.init();
			dropOffCountryInput.init('US');
			dropoffDateTimeInput.init(getDateTimeString(new Date()));
			setDropOffDateTimeObjectCanLoad(true);
		}

		// The value from dropoffDateTimeInput needs a completed useEffect function
		// to run before its value can be set.
		if (dropoffDateTimeObjectCanLoad && loadDropoffDateTimeObject.canLoad()) {
			setDropOffDateTimeObject(getDateTime(dropoffDateTimeInput.value));
		}
	}, [
		loadDropoffAddressData,
		myWorkingOrderSessionManager,
		dropOffAddressOneInput,
		dropOffAddressTwoInput,
		dropOffPostalCodeInput,
		dropOffCityInput,
		dropOffStateInput,
		dropOffCountryInput,
		dropoffDateTimeInput,
		dropoffDateTimeObjectCanLoad,
		loadDropoffDateTimeObject,
	]);

	return (
		<>
			<h3>Dropoff Address</h3>
			<Row>
				<Col md>
					<Form.Group>
						<Form.Label>Dropoff Address Line One</Form.Label>
						<Form.Control
							type='text'
							{...dropOffAddressOneInput.bind}
							placeholder='Dropoff Address Line One'
							required
							isInvalid={errorMessageHash[DROPOFF_ADDRESS_ONE]}
						/>
						<Form.Control.Feedback type='invalid'>
							{errorMessageHash[DROPOFF_ADDRESS_ONE]}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
				<Col md>
					<Form.Group>
						<Form.Label>Dropoff Address Line Two</Form.Label>
						<Form.Control
							type='text'
							{...dropOffAddressTwoInput.bind}
							placeholder='Dropoff Address Line Two'
							isInvalid={errorMessageHash[DROPOFF_ADDRESS_TWO]}
						/>
						<Form.Control.Feedback type='invalid'>
							{errorMessageHash[DROPOFF_ADDRESS_TWO]}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col sm>
					<Form.Group>
						<Form.Label>Dropoff Postal Code</Form.Label>
						<Form.Control
							type='text'
							{...dropOffPostalCodeInput.bind}
							placeholder='Dropoff Postal Code'
							required
							isInvalid={errorMessageHash[DROPOFF_POSTAL_CODE]}
						/>
						<Form.Control.Feedback type='invalid'>
							{errorMessageHash[DROPOFF_POSTAL_CODE]}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
				<Col sm>
					<Form.Group>
						<Form.Label>Dropoff City</Form.Label>
						<Form.Control
							type='text'
							{...dropOffCityInput.bind}
							placeholder='Dropoff City'
							isInvalid={errorMessageHash[DROPOFF_CITY]}
						/>
						<Form.Control.Feedback type='invalid'>
							{errorMessageHash[DROPOFF_CITY]}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
				<Col sm>
					<Form.Group>
						<Form.Label>Dropoff State</Form.Label>
						<Form.Control
							type='text'
							{...dropOffStateInput.bind}
							placeholder='Dropoff State'
							isInvalid={errorMessageHash[DROPOFF_STATE]}
						/>
						<Form.Control.Feedback type='invalid'>
							{errorMessageHash[DROPOFF_STATE]}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
				<Col sm>
					<Form.Group>
						<Form.Label>Dropoff Country</Form.Label>
						<Form.Control
							type='text'
							{...dropOffCountryInput.bind}
							isInvalid={errorMessageHash[DROPOFF_COUNTRY]}
							placeholder='Dropoff Country'
						/>
						<Form.Control.Feedback type='invalid'>
							{errorMessageHash[DROPOFF_COUNTRY]}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col sm={7}>
					<Form.Group>
						<Form.Label>Dropoff Time and Date</Form.Label>
						<Form.Control
							as={DateTimePicker}
							onChange={(value) => {
								setDropOffDateTimeObject(value);
								dropoffDateTimeInput.setValue(getDateTimeString(value));
							}}
							value={dropoffDateTimeDisplay}
							isInvalid={errorMessageHash[DROPOFF_DATE_TIME]}
						/>
						<Form.Control.Feedback type='invalid'>
							{errorMessageHash[DROPOFF_DATE_TIME]}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
			</Row>
		</>
	);
}
