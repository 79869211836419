const dateFormat = require('dateformat');

export const DATE_TIME_FORMAT = "UTC:yyyy-mm-dd'T'HH:MM:ss'Z'";
export const DATE_TIME_PRETTY_FORMAT = 'DDDD m/d/yyyy h:Mtt Z'; ;

export function getDateTimeString(dateTimeObject) {
	return dateFormat(dateTimeObject, DATE_TIME_FORMAT);
}

export function getDateTime(dateTimeString) {
	return new Date(dateFormat(dateTimeString, DATE_TIME_FORMAT));
}

export function validDateString(dateTimeString) {
	if (!dateTimeString) {
		return false;
	}

	try {
		dateFormat(dateTimeString, DATE_TIME_FORMAT);
		return true;
	} catch {
		return false;
	}
}

export function prettyDateFromString(dateTimeString){
	if(validDateString(dateTimeString)){
		return dateFormat(dateTimeString, DATE_TIME_PRETTY_FORMAT);
	}
	return '';
}
