export default class Errors {
	constructor(errorResponse) {
		let workingErrors =
			errorResponse !== undefined &&
			errorResponse.response !== undefined &&
			errorResponse.response.data !== undefined
				? errorResponse.response.data
				: undefined;

		// Trim for errors with extra white spaces
		if (workingErrors) {
			Object.keys(workingErrors).forEach((error) => {
				let trimmedError = error.trim();
				if (workingErrors[trimmedError] === undefined) {
					workingErrors[trimmedError] = workingErrors[error];
					delete workingErrors[error];
				}
			});
		}
		this.errors = workingErrors;
	}

	errorMessage(field) {
		if (this.errors !== undefined && this.errors[field] !== undefined) {
			let fieldErrors = this.errors[field];
			if (!Array.isArray(fieldErrors)) {
				fieldErrors = [fieldErrors];
			}
			return fieldErrors.join(',');
		}
		return undefined;
	}

	hasError(field) {
		return this.errors !== undefined && this.errors[field] !== undefined;
	}

	log() {
		if (this.errors !== undefined) {
			console.error(this.errors);
		} else {
			console.info('No errors found');
		}
	}
}
