import React from 'react';
import { StyledModalBackButton } from './Modal.styled';

export default class ModalBackButton extends React.Component {
	render() {
		return (
			<>
				{(this.props.disabled === false || this.props.disabled === undefined )&& (
					<StyledModalBackButton onClick={this.props.onClick}>
						&#10132;
					</StyledModalBackButton>
				)}
				{this.props.disabled === true &&
					this.props.allowClickOnDisabled !== true && (
						<StyledModalBackButton disabled>&#10132;</StyledModalBackButton>
					)}
				{this.props.disabled === true &&
					this.props.allowClickOnDisabled === true && (
						<StyledModalBackButton onClick={this.props.onClick} falseDisabled>
							&#10132;
						</StyledModalBackButton>
					)}
			</>
		);
	}
}
