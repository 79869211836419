import React, { useState, useRef, useEffect } from 'react';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { useScreenType } from '../../hooks/useScreenType';
import BarNav from './BarNav';
import Burger from './Burger';
import SideNav from './SideNav';
import { theme } from '../../theme';
import { withRouter } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import RunningReceipt from '../RunningReceipt/RunningReceipt';
import ShoeberShoppingCart from '../Core/ShoeberShoppingCart/ShoeberShoppingCart';
import LoadOnce from '../../hooks/loadOnce';
import {
	createLocalStorage,
	MY_REPAIR_ITEMS_NAME,
} from '../../utils/localStorageManager/localStorage';
import { StyledMenu, StyledMenuButton } from './Navigation.styled';

function Navigation({
	openRunningReceipt,
	setOpenRunningReceipt,
	setActiveEditItem,
}) {
	const screenType = useScreenType();

	const sideMenuRef = useRef();

	const [openSideMenu, setOpenSideMenu] = useState(false);
	useOnClickOutside(sideMenuRef, () => setOpenSideMenu(false));

	const runningReceiptRef = useRef();
	useOnClickOutside(runningReceiptRef, () => setOpenRunningReceipt(false));

	const [myCart, setMyCart] = useState([]);

	const addMyRepairItemsManager = LoadOnce();

	const myCartLocalStorage = createLocalStorage(MY_REPAIR_ITEMS_NAME, 'array');

	useEffect(() => {
		if (addMyRepairItemsManager.canLoad()) {
			myCartLocalStorage.addCallback(() => setMyCart(myCartLocalStorage.get()));
		}
	}, [addMyRepairItemsManager, myCartLocalStorage]);

	function menu() {
		if (screenType.isMobile) {
			return (
				<Navbar className='bg-light'>
					<div ref={sideMenuRef}>
						<Burger
							open={openSideMenu}
							setOpen={setOpenSideMenu}
							setOpenRunningReceipt={setOpenRunningReceipt}
						/>
						<SideNav
							openSideMenu={openSideMenu}
							setOpenSideMenu={setOpenSideMenu}
							openRunningReceipt={openRunningReceipt}
							setOpenRunningReceipt={setOpenRunningReceipt}
						/>
					</div>

					<Nav className='ml-auto'>
						<div ref={runningReceiptRef}>
							<StyledMenuButton>
								<Nav.Link
									onClick={() => setOpenRunningReceipt(!openRunningReceipt)}>
										<Nav.Link href='#/checkout' disabled={myCart.length === 0}>
							<ShoeberShoppingCart />
						</Nav.Link>
								</Nav.Link>
							</StyledMenuButton>
							
							<RunningReceipt
								mobile={true}
								open={openRunningReceipt}
								setOpenRunningReceipt={setOpenRunningReceipt}
								setActiveEditItem={setActiveEditItem}
							/>
						</div>
					</Nav>
					<Nav>
						{/* <Nav.Link href='#/checkout' disabled={myCart.length === 0}>
							<ShoeberShoppingCart />
						</Nav.Link> */}
					</Nav>
				</Navbar>
			);
		} else {
			return (
				<BarNav
					openRunningReceipt={openRunningReceipt}
					setOpenRunningReceipt={setOpenRunningReceipt}
					setActiveEditItem={setActiveEditItem}
				/>
			);
		}
	}

	return (
		<>
			<StyledMenu>{menu()}</StyledMenu>
			<div style={{ height: theme.menuHeight + 'px' }} />
		</>
	);
}

export default withRouter(Navigation);
