import React from 'react';
import styled from 'styled-components';

export const StyledLocationMarker = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 18px;
	height: 18px;
	border: 2px solid #000;
	border-radius: ${(props) => (props.isStore ? '0%' : '100%')};
	user-select: none;
	transform: translate(-50%, -50%);
	cursor: pointer;
	z-index: 3;
	background-color: ${(props) =>
		props.isStore
			? ({ theme }) => theme.secondaryDark
			: ({ theme }) => theme.primaryHover};
`;

function LocationMarker({ info, setLocationModalInfo, toggle }) {
	return (
		<StyledLocationMarker
			isStore={info.isStore === true}
			onClick={() => {
				setLocationModalInfo({
					location: info.location,
					image: info.image,
					identifier: info.identifier,
				});
				toggle();
			}}
		/>
	);
}

export default LocationMarker;
