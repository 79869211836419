import React from 'react';
import { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import ShoeberButton from '../Core/ShoeberButton';
import ShoeberLink from '../Core/ShoeberLink';
import { ShoeberBackendApi } from '../../api/ShoeberBackendApi';
import { withRouter } from 'react-router-dom';
import useRedirectWithAlert from '../../hooks/useRedirectWithAlert';
import { useInput } from '../../hooks/input';
import Errors from '../../utils/Errors';
import {
	formatPhoneNumberDisplay,
	formatPhoneNumber,
} from '../../utils/phoneNumber';
import { SUCCESS } from '../Core/ShoeberAlert';
import Loading from '../Core/Loading/Loading';

import './Signup.css';

function Signup() {
	const redirectWithAlert = useRedirectWithAlert();

	const accountApi = ShoeberBackendApi.getAccountApi();

	const [loading, setIsLoading] = useState(false);

	const firstNameInput = useInput('');
	const lastNameInput = useInput('');
	const emailInput = useInput('');
	const phoneNumberInput = useInput('');
	const passwordInput = useInput('');
	const passwordConfirmInput = useInput('');

	function resetErrorMessages() {
		firstNameInput.setErrorMessage(undefined);
		lastNameInput.setErrorMessage(undefined);
		emailInput.setErrorMessage(undefined);
		phoneNumberInput.setErrorMessage(undefined);
		passwordInput.setErrorMessage(undefined);
		passwordConfirmInput.setErrorMessage(undefined);
	}

	function handleSubmit(event) {
		resetErrorMessages();
		event.preventDefault();
		setIsLoading(true);

		accountApi
			.accountPost({
				first_name: firstNameInput.value,
				last_name: lastNameInput.value,
				email: emailInput.value,
				phone_number: formatPhoneNumber(phoneNumberInput.value),
				password: passwordInput.value,
				password_confirm: passwordConfirmInput.value,
			})
			.then((response) => {
				redirectWithAlert.redirectWithAlert(
					'/login',
					'Success',
					'Your account has been created',
					SUCCESS
				);
			})
			.catch((errorResponse) => {
				let errors = new Errors(errorResponse);
				firstNameInput.setErrorMessage(errors.errorMessage('first_name'));
				lastNameInput.setErrorMessage(errors.errorMessage('last_name'));
				emailInput.setErrorMessage(errors.errorMessage('email'));
				phoneNumberInput.setErrorMessage(errors.errorMessage('phone_number'));
				passwordInput.setErrorMessage(errors.errorMessage('password'));
				passwordConfirmInput.setErrorMessage(
					errors.errorMessage('password_confirm')
				);
				setIsLoading(false);
			});
	}

	return (
		<>
			<Loading loading={loading} />
			<Form className='mx-auto signup'>
				<img
					alt='Shoeber Dropbox'
					src='https://s3.us-east-2.amazonaws.com/shoeber.html.helpers.graffititech.co/images/static/shoeberLogoLarge.png '
					height='220'
					style={{ paddingBottom: '10px' }}
				/>
				<Row>
					<h2 className='createAccountHeader'>Create Shoeber Account</h2>
				</Row>
				<Row>
					<Col>
						<Form.Group controlId='firstName'>
							<Form.Label className='label'>First Name</Form.Label>
							<Form.Control
								name='first_name'
								type='text'
								{...firstNameInput.bind}
								placeholder='First name'
								isInvalid={firstNameInput.errorMessage}
							/>
							<Form.Control.Feedback type='invalid'>
								{firstNameInput.errorMessage}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId='lastName'>
							<Form.Label className='label'>Last Name</Form.Label>
							<Form.Control
								name='last_name'
								type='text'
								{...lastNameInput.bind}
								placeholder='Last name'
								isInvalid={lastNameInput.errorMessage}
							/>
							<Form.Control.Feedback type='invalid'>
								{lastNameInput.errorMessage}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>
				<Form.Group controlId='email'>
					<Form.Label className='label'>Email address</Form.Label>
					<Form.Control
						name='email'
						type='email'
						{...emailInput.bind}
						placeholder='Enter email'
						autoComplete='email'
						isInvalid={emailInput.errorMessage}
					/>
					<Form.Control.Feedback type='invalid'>
						{emailInput.errorMessage}
					</Form.Control.Feedback>
				</Form.Group>

				<Form.Group controlId='phoneNumber'>
					<Form.Label className='label'>Phone Number</Form.Label>
					<Form.Control
						name='phone_number'
						{...phoneNumberInput.bind}
						onChange={(event) => {
							phoneNumberInput.setValue(
								formatPhoneNumberDisplay(event.target.value)
							);
						}}
						isInvalid={phoneNumberInput.errorMessage}
						placeholder='Enter phone number'
					/>
					<Form.Control.Feedback type='invalid'>
						{phoneNumberInput.errorMessage}
					</Form.Control.Feedback>
				</Form.Group>

				<Form.Group controlId='password'>
					<Form.Label className='label'>Password</Form.Label>
					<Form.Control
						name='password'
						type='password'
						required
						{...passwordInput.bind}
						isInvalid={passwordInput.errorMessage}
						placeholder='Password'
						autoComplete='new-password'
					/>
					<Form.Control.Feedback type='invalid'>
						{passwordInput.errorMessage}
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group controlId='password_confirm'>
					<Form.Label className='label'>Retype Password</Form.Label>
					<Form.Control
						name='password_confirm'
						type='password'
						required
						{...passwordConfirmInput.bind}
						placeholder='Retype Password'
						autoComplete='new-password'
						isInvalid={passwordConfirmInput.errorMessage}
					/>
					<Form.Control.Feedback type='invalid'>
						{passwordConfirmInput.errorMessage}
					</Form.Control.Feedback>
				</Form.Group>
				<ShoeberButton
					text='Submit'
					onClick={handleSubmit}
					clickOnEnter={true}
				/>
				<br />
				<ShoeberLink to='/login' text='Already have an account?' />
			</Form>
		</>
	);
}

export default withRouter(Signup);
