import { DROPBOX_ORDER } from './OrderType';
import LoadOnce from '../../../hooks/loadOnce';
import {
	createSessionStorage,
	MY_WORKING_ORDER,
} from '../../../utils/sessionStorageManager/sessionStorage';
import { Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import ShoeberButton from '../../Core/ShoeberButton';
import styled from 'styled-components';
import useModal from '../../../hooks/useModal';
import LocationSelectModal from './LocationSelectModal';
import { format } from 'react-string-format';

const StyledLocationIdentifier = styled.div`
	.form-group .form-label {
		padding-right: 10px;
		padding-left: 30px;
	}

	.form-group .custom {
		width: 50px;
	}

	.form-group .customWide {
		width: 100px;
	}
`;

export default function LocationSelect({ orderType, errorMessageHash }) {
	const myWorkingOrderSessionManager = createSessionStorage(
		MY_WORKING_ORDER,
		'json'
	);

	const DROPBOX_IDENTIFIER = 'dropbox_identifier';

	const [locationPrefix, setLocationPrefix] = useState('');
	const locationSelectModal = useModal();
	const [locationPostfix, setLocationPostfix] = useState('');

	const [selectedLocation, setSelectedLocation] = useState(undefined);

	const setWorkingOrderManager = LoadOnce();

	function setLocationInformation(locationPrefix) {
		setLocationPrefix(locationPrefix);
	}

	function handlePostfixUpdated(event) {
		let newValue = event.target.value;
		setLocationPostfix(newValue);
		if (newValue.length === 5) {
			myWorkingOrderSessionManager.set(
				format('{0}-{1}', locationPrefix, newValue),
				DROPBOX_IDENTIFIER
			);
			setLocationPostfix(newValue);
		}
	}

	useEffect(() => {
		if (setWorkingOrderManager.canLoad()) {
			myWorkingOrderSessionManager.addCallback(() => {
				let dropboxIdentifier = myWorkingOrderSessionManager.get(
					DROPBOX_IDENTIFIER
				);
				if (dropboxIdentifier && dropboxIdentifier.includes('-')) {
					let splitDropboxIdentifier = dropboxIdentifier.split('-');
					setLocationPostfix(splitDropboxIdentifier[1]);
					setLocationPrefix(splitDropboxIdentifier[0]);
				}
			});
		}
	}, [myWorkingOrderSessionManager, setWorkingOrderManager]);

	return (
		<>
			{orderType === DROPBOX_ORDER && (
				<>
					<h3>Dropbox Order</h3>
					<StyledLocationIdentifier>
						<Form.Group>
							<ShoeberButton
								onClick={locationSelectModal.toggle}
								text='Select Location'
							/>
							<Form.Label>Dropbox Identifer:</Form.Label>
							<Form.Control
								type='text'
								value={locationPrefix}
								disabled={true}
								custom
								inline='true'
							/>
							<span>-</span>
							<Form.Control
								type='text'
								custom
								className='customWide'
								value={locationPostfix}
								inline='true'
								onChange={handlePostfixUpdated}
								disabled={!locationPrefix}
								maxLength='5'
								isInvalid={errorMessageHash[DROPBOX_IDENTIFIER]}
							/>
							<Form.Control.Feedback type='invalid'>
								{errorMessageHash[DROPBOX_IDENTIFIER]}
							</Form.Control.Feedback>
						</Form.Group>
					</StyledLocationIdentifier>
					<LocationSelectModal
						isShowing={locationSelectModal.isShowing}
						toggle={locationSelectModal.toggle}
						setLocationInformation={setLocationInformation}
						selectedLocation={selectedLocation}
						setSelectedLocation={setSelectedLocation}
					/>
				</>
			)}
		</>
	);
}
