import LocalStorageManager from './localStorageManager';

export const SHOEBER_PRODUCTS = 'shoeberProducts';
export const MY_REPAIR_ITEMS_NAME = 'myRepairItems';
export const SHOEBER_CATEGORIES = 'shoeberCategories';
export const TYPES_BY_CATEGORY = 'typesByCategory';

class LocalStorage {
	constructor(itemName, type, defaultValue, ttl) {
		this.itemName = itemName

		LocalStorageManager.addManagedStorage(
			itemName,
			type,
			defaultValue,
			ttl
		);
	}

	removeManagedStorage() {
		LocalStorageManager.removeManagedStorage(this.itemName);
	}

	addCallback(callback) {
		LocalStorageManager.addCallback(this.itemName, callback);
	}

	getDefaultValue() {
		return LocalStorageManager.getDefaultValue(this.itemName);
	}

	get(key = undefined) {
		return LocalStorageManager.get(this.itemName, key);
	}

	set(value, key) {
		LocalStorageManager.set(this.itemName, value, key);
	}

	add(value, key) {
		LocalStorageManager.add(this.itemName, value, key);
	}

	remove() {
		LocalStorageManager.remove(this.itemName);
	}

	reset() {
		LocalStorageManager.reset(this.itemName);
	}
}

export function createLocalStorage(
	itemName,
	type,
	defaultValue = undefined,
	ttl = undefined
) {
	return new LocalStorage(itemName, type, defaultValue, ttl);
}
