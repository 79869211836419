import React, { useEffect, useState, useMemo } from 'react';
import LoadOnce from '../../hooks/loadOnce';
import { ShoeberBackendApi } from '../../api/ShoeberBackendApi';
import { HasRequiredAccountType } from '../../hooks/confirmedAccountType';
import Loading from '../Core/Loading/Loading';
import ModalCloseButton from '../Core/Modal/ModalCloseButton';
import ModalBackButton from '../Core/Modal/ModalBackButton';
import useModal from '../../hooks/useModal';
import { Row, Modal, Col } from 'react-bootstrap';
import ShoeberLink from '../Core/ShoeberLink';
import ShoeberLine from '../Core/ShoeberLine';
import ShoeberButton from '../Core/ShoeberButton';
import styled from 'styled-components';
import { Accordion, Card, ListGroup } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';
import { prettyDateFromString } from '../../utils/date';
import { StyledLocationMarker } from '../Core/LocationMarker';
import { format } from 'react-string-format';

const RECEIVED = 'received';
const IN_PROGRESS = 'in_progress';
const NEED_INFO = 'need_info';
const ISSUES = 'issues';
const COMPLETE_CODE = 'COM';

const CANCELED = 'canceled';

const StyledOrderSection = styled.div`
	padding-top: 10px;

	.card-header {
		background: ${({ theme }) => theme.primaryDark};
		color: ${({ theme }) => theme.primaryLight};

		&:hover {
			color: ${({ theme }) => theme.primaryHover};
			cursor: pointer;
		}
	}
`;

export default function MyOrders() {
	const [loggedIn, setLoggedIn] = useState(false);
	HasRequiredAccountType(function () {
		setLoggedIn(true);
	});

	const defaultMapInformation = {
		center: {
			lat: 34.0755429,
			lng: -118.3756987,
		},
		zoom: 10,
	};

	const orderApi = ShoeberBackendApi.getOrderApi();

	const loadProfile = LoadOnce();

	const detailModal = useModal();

	const [dropboxInfo, setDropboxInfo] = useState('');
	const [dropboxInfoIsLoading, setDropboxInfoIsLoading] = useState(false);

	const [modalHeader, setModalHeader] = useState('');
	const [modalBody, setModalBody] = useState('');

	// Order types
	const [inProgressOrders, setInProgressOrders] = useState(undefined);
	const [receivedOrders, setReceivedOrders] = useState(undefined);
	const [needInfoOrders, setNeedInfoOrders] = useState(undefined);
	const [issueOrders, setIssueOrders] = useState(undefined);
	const [completeOrders, setCompleteOrders] = useState(undefined);
	const [canceledOrders, setCanceledOrders] = useState(undefined);

	const ORDER_MAP = useMemo(
		() => [
			{
				setValue: setInProgressOrders,
				searchParam: IN_PROGRESS,
			},
			{
				setValue: setReceivedOrders,
				searchParam: RECEIVED,
			},
			{
				setValue: setNeedInfoOrders,
				searchParam: NEED_INFO,
			},
			{
				setValue: setIssueOrders,
				searchParam: ISSUES,
			},
			{
				setValue: setCompleteOrders,
				searchParam: COMPLETE_CODE,
				isStatusCode: true,
			},
			{
				setValue: setCanceledOrders,
				searchParam: CANCELED,
			},
		],
		[]
	);

	const ALL_ORDERS = [
		{
			items: inProgressOrders,
			header: 'In Progress Orders',
		},
		{
			items: receivedOrders,
			header: 'Received Orders',
		},
		{
			items: needInfoOrders,
			header: 'Need Info Orders',
		},
		{
			items: issueOrders,
			header: 'Has Issues Orders',
		},
		{
			items: completeOrders,
			header: 'Completed Orders',
		},
		{
			items: canceledOrders,
			header: 'Canceled Orders',
		},
	];

	function noOrders() {
		let noOrders = true;
		ALL_ORDERS.forEach((orders) => {
			if (orders.items !== undefined && orders.items.length > 0) {
				noOrders = false;
			}
		});
		return noOrders;
	}

	function isLoading() {
		let isLoading = false;
		ALL_ORDERS.forEach((orders) => {
			if (orders.items === undefined) {
				isLoading = true;
			}
		});
		return isLoading;
	}

	useEffect(() => {
		if (loggedIn && loadProfile.canLoad()) {
			ORDER_MAP.forEach((orderType) => {
				let searchParam = orderType['searchParam'];

				let status = !orderType['isStatusCode'] ? searchParam : undefined;
				let statusCode = orderType['isStatusCode'] ? searchParam : undefined;

				orderApi
					.orderGet(status, statusCode)
					.then((response) => {
						orderType.setValue(response.data);
					})
					.catch((errorResponse) => {
						orderType.setValue([]);
					});
			});

			setDropboxInfoIsLoading(true);
			ShoeberBackendApi.getDropboxApi()
				.dropboxGet()
				.then((response) => {
					let dropboxesByPrefix = {};
					response.data.forEach((dropbox) => {
						dropboxesByPrefix[dropbox.identifier] = dropbox;
					});
					setDropboxInfo(dropboxesByPrefix);
					setDropboxInfoIsLoading(false);
				});
		}
	}, [ORDER_MAP, loadProfile, loggedIn, orderApi]);

	function renderLocation(address) {
		let lat = address.geocode.lat;
		let lng = address.geocode.lng;
		return (
			<>
				<div style={{ height: '300px', width: '100%' }}>
					<GoogleMapReact
						bootstrapURLKeys={{
							key: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY,
						}}
						defaultCenter={{ lat: lat, lng: lng }}
						defaultZoom={defaultMapInformation.zoom}>
						<StyledLocationMarker lat={lat} lng={lng} />
					</GoogleMapReact>
				</div>
				<Row style={{ paddingTop: '10px' }}>
					<Col>
						<h5>
							{address.address_one}
							{address.address_two != null ? ' ' + address.address_two : ''}
						</h5>
					</Col>
				</Row>
				<Row>
					<Col>
						{address.city}, {address.state}
					</Col>
				</Row>
				<Row>
					<Col>{address.postal_code}</Col>
				</Row>
				<Row>
					<Col>{address.country}</Col>
				</Row>
			</>
		);
	}

	function showDropbox(dropboxIdentiferPrefix) {
		setModalHeader('Dropbox Location');
		setModalBody(renderLocation(dropboxInfo[dropboxIdentiferPrefix].location));

		detailModal.toggle();
	}

	function showAddress(address, name) {
		setModalHeader(name);
		setModalBody(renderLocation(address));

		detailModal.toggle();
	}

	function columnOne(order) {
		return (
			<div>
				<p style={{marginBottom: "0px"}}>Delivery Date:</p>
				<ShoeberLink
					style={{ textAlign: 'center' }}
					text={prettyDateFromString(order.requested_dropoff_date_time)}
					onClick={() => {
						showAddress(order.dropoff_address, 'Dropoff Address');
					}}
				/>
			</div>
		);
	}

	function columnTwo(order) {
		if (order.dropbox_identifier) {
			let prefix = order.dropbox_identifier.split('-')[0];
			return (
				<div style={{ marginTop: '20%' }}>
					<p style={{ marginBottom: '0px' }}>Dropbox Identifer:</p>
					<ShoeberLink
						text={order.dropbox_identifier}
						onClick={() => {
							showDropbox(prefix);
						}}
						disabled={!dropboxInfo[prefix]}
					/>
				</div>
			);
		} else {
			return (
				<>
					<p>Pickup Date:</p>
					<ShoeberLink
						text={prettyDateFromString(order.requested_pickup_date_time)}
						onClick={() => {
							showAddress(order.pickup_address, 'Pickup Location');
						}}
					/>
				</>
			);
		}
	}

	function showItems(items) {
		setModalHeader('Order Items');

		setModalBody(
			<>
				<ListGroup>
					{items.map((item, itemIndex) => {
						return (
							<ListGroup.Item
								key={itemIndex}
								variant={itemIndex % 2 === 0 ? '' : 'secondary'}>
								<h5>{item.display}</h5>
								<ListGroup>
									{item.services.map((service, serviceIndex) => {
										
										let repairDisplayInfo =
											service.service;
										return (
											<ListGroup.Item
												key={format('{0}-{1}', itemIndex, serviceIndex)}
												variant={serviceIndex % 2 === 0 ? '' : 'secondary'}>
												{format(
													'{0} - {1}',
													format(
														'{0}: {1}',
														repairDisplayInfo.name,
														repairDisplayInfo.description
													),
													service.status
												)}
											</ListGroup.Item>
										);
									})}
								</ListGroup>
							</ListGroup.Item>
						);
					})}
				</ListGroup>
			</>
		);

		detailModal.toggle();
	}

	function columnThree(order) {
		return (
			<ShoeberLink
				style={{marginTop: "25%"}}
				text={'Items (' + order.items.length + ')'}
				onClick={() => {
					showItems(order.items);
				}}
			/>
		);
	}

	function showNotes(order) {
		setModalHeader('Order Notes');

		setModalBody(order.notes || 'No notes for this order.');

		detailModal.toggle();
	}

	function columnFour(order) {
		return (
			<ShoeberLink
				style={{ marginTop: '25%' }}
				text={'Notes'}
				onClick={() => {
					showNotes(order);
				}}
			/>
		);
	}

	return (
		<>
			<Loading loading={isLoading()} />
			<Loading loading={dropboxInfoIsLoading} />
			<Accordion defaultActiveKey='0'>
				{ALL_ORDERS.map((orderInfo, index) => {
					if (orderInfo.items && orderInfo.items.length > 0) {
						return (
							<StyledOrderSection key={index}>
								<Card>
									<Accordion.Toggle as={Card.Header} eventKey={index}>
										{orderInfo.header} ({orderInfo.items.length})
									</Accordion.Toggle>
									<Accordion.Collapse eventKey={index}>
										<Card.Body>
											{orderInfo.items.map((order, orderIndex) => {
												return (
													<Row
														float='center'
														key={format('{0}-{1}', index, orderIndex)}>
														<Col>{columnOne(order)}</Col>
														<Col>{columnTwo(order)}</Col>
														<Col>{columnThree(order)}</Col>
														<Col>{columnFour(order)}</Col>

														{index + 1 !== orderInfo.items.length && (
															<ShoeberLine />
														)}
													</Row>
												);
											})}
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							</StyledOrderSection>
						);
					} else {
						return <div key={index}></div>;
					}
				})}
			</Accordion>

			<Modal show={detailModal.isShowing} onHide={detailModal.toggle}>
				<Modal.Header>
					<ModalBackButton onClick={detailModal.toggle} />
					<Modal.Title>{modalHeader}</Modal.Title>
					<ModalCloseButton onClick={detailModal.toggle} />
				</Modal.Header>
				<Modal.Body>{modalBody}</Modal.Body>
				<Modal.Footer>
					<ShoeberButton onClick={detailModal.toggle} secondary text='Close' />
				</Modal.Footer>
			</Modal>

			{loggedIn && !isLoading() && noOrders() && (
				<>
					You currently have no orders. To create an order go:{' '}
					<ShoeberLink to='/' text='here' />
				</>
			)}
		</>
	);
}
