import {
	createSessionStorage,
	ORDER_TYPE,
} from '../../../utils/sessionStorageManager/sessionStorage';
import LoadOnce from '../../../hooks/loadOnce';
import { useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import styled from 'styled-components';
import { useDisplayColumns } from '../../../hooks/useScreenType';

const StyledSelectOrderType = styled.div`
	img {
		max-width: 100%;
		margin-top: 8px;
		max-height: 75px;
	}
`;

const StyledOrderTypeSelectButton = styled.div`
	background: ${({ theme }) => theme.primaryDark};
	border-color: ${({ theme }) => theme.primaryDark};

	color: ${(props) =>
		props.selected
			? ({ theme }) => theme.primaryHover
			: ({ theme }) => theme.primaryLight};

	&:hover {
		color: ${({ theme }) => theme.primaryHover};
		background: ${({ theme }) => theme.primaryDark};
		border-color: ${({ theme }) => theme.primaryDark};
	}

	display: inline-block;
	border-radius: 5px;
	padding: 7px 10px 7px 10px;
	margin-bottom: 15px;

	&:hover {
		transform: scale(1.008);
		cursor: pointer;
	}

	&:focus {
		outline-color: ${({ theme }) => theme.primaryDark};
	}

	&:active {
		background: ${({ theme }) => theme.primaryDark};
		border-color: ${({ theme }) => theme.primaryDark};
	}
`;
export const PICKUP_ORDER = 'pickup';
export const DROPBOX_ORDER = 'dropbox';

export default function OrderType({ orderType, setOrderType }) {
	const displayColumns = useDisplayColumns();

	const myWorkingOrderSessionManager = createSessionStorage(ORDER_TYPE, 'string');

	function handleValueChange(event) {
		myWorkingOrderSessionManager.set(event.target.id);
	}

	const orderTypePageLoad = LoadOnce();

	useEffect(() => {
		if (orderTypePageLoad.canLoad()) {
			myWorkingOrderSessionManager.addCallback(() => {
				setOrderType(myWorkingOrderSessionManager.get());
			});
		}
	}, [myWorkingOrderSessionManager, orderTypePageLoad, setOrderType]);

	return (
		<StyledSelectOrderType>
			<h3>Order Type</h3>
			<Row className='justify-content-md-center'>
				<Col xs={displayColumns.getColumnSize()}>
					<StyledOrderTypeSelectButton
						id={PICKUP_ORDER}
						selected={orderType === PICKUP_ORDER}
						onClick={handleValueChange}>
						<Image
							id={PICKUP_ORDER}
							alt='pickup'
							src='https://s3.us-east-2.amazonaws.com/shoeber.html.helpers.graffititech.co/images/static/no_image.jpg'
						/>
						<p>Pickup Order</p>
					</StyledOrderTypeSelectButton>
				</Col>
				<Col xs={displayColumns.getColumnSize()}>
					<StyledOrderTypeSelectButton
						onClick={handleValueChange}
						selected={orderType === DROPBOX_ORDER}
						id={DROPBOX_ORDER}>
						<Image
							id={DROPBOX_ORDER}
							alt='pickup'
							src='https://s3.us-east-2.amazonaws.com/shoeber.html.helpers.graffititech.co/images/static/no_image.jpg'
						/>
						<p>Dropoff Order</p>
					</StyledOrderTypeSelectButton>
				</Col>
			</Row>
		</StyledSelectOrderType>
	);
}
