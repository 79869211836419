import { useState, useEffect } from 'react';
import LoadOnce from '../../hooks/loadOnce';
import {
	createLocalStorage,
	MY_REPAIR_ITEMS_NAME,
} from '../../utils/localStorageManager/localStorage';
import { Row, Col } from 'react-bootstrap';
import { StyledRunningReceipt } from './RunningReceipt.styled';
import ShoeberLink from '../Core/ShoeberLink';
import MyCartItems from '../Core/ShoeberShoppingCart/MyCartItems';

export default function RunningReceipt({
	open,
	mobile,
	setOpenRunningReceipt,
	setActiveEditItem,
}) {
	const myCartLocalStorage = createLocalStorage(MY_REPAIR_ITEMS_NAME, 'array');
	const [myCart, setMyCart] = useState([]);

	const addMyRepairItemsManager = LoadOnce();

	useEffect(() => {
		if (addMyRepairItemsManager.canLoad()) {
			myCartLocalStorage.addCallback(() => setMyCart(myCartLocalStorage.get()));
		}
	});

	function handleSetActiveEditItem(item) {
		setOpenRunningReceipt(false);
		setActiveEditItem(item);
	}

	return (
		<StyledRunningReceipt open={open} mobile={mobile}>
			<Row style={{ marginBottom: '-10px' }}>
				<Col>
					<h4>Your Items</h4>
				</Col>
				{myCart.length > 0 && (
					<Col style={{ paddingTop: '5px' }}>
				
					</Col>
				)}
			</Row>
			<MyCartItems setActiveEditItem={handleSetActiveEditItem} />
			{myCart.length === 0 && (
				<div style={{ paddingTop: '10px' }}>Add Items to repair.</div>
			)}
					<ShoeberLink
							to='checkout'
							style={{ border: '2px solid black', textAlign: 'center', background: 'orange', marginRight: '55%'}}
							className='fixed-bottom fixed-left'
							text='Checkout'
							variant='light'
							onClick={() => setOpenRunningReceipt(false)}
						/>
						<ShoeberLink
							to='home'
							style={{ border: '2px solid black', textAlign: 'center', background: 'orange', marginLeft: '55%'}}
							className='fixed-bottom fixed right'
							text='Add More Repairs'
							variant='light'
							onClick={() => setOpenRunningReceipt(false)}
						/>
		</StyledRunningReceipt>
	);
}
