import { useEffect, useState, useCallback } from 'react';
import { theme } from '../../theme';


function RepairSelectChecks({
	setRepairParent,
	setChildRepairs,
	repairChildSelectModal,
	toggleRepair,
	itemRepairs,
	repairs,
	repairOffset,
}) {
	const [activeRepairs, setActiveRepairs] = useState([]);

	function hasValidChildren(repair) {
		return (
			repair['children'] !== undefined &&
			Array.isArray(repair['children']) &&
			repair['children'].length > 0
		);
	}

	function repairSelected(repair) {
		if (hasValidChildren(repair)) {
			setRepairParent(repair);
			setChildRepairs(repair['children']);
			repairChildSelectModal.toggle();
		} else {
			toggleRepair(repair);
		}
	}

	const shownRepairs = useCallback(
		function () {
			let repairStatingPoint = repairOffset * 6;
			let shownRepairs = [];
			for (let i = repairStatingPoint; i < repairStatingPoint + 6; i++) {
				if (repairs.length >= i) {
					shownRepairs.push(repairs[i]);
				} else {
					break;
				}
			}
			return shownRepairs;
		},
		[repairOffset, repairs]
	);

	useEffect(() => {
		setActiveRepairs(() => {
			let activeRepairs = [];
			let ar = itemRepairs.map((x) => (x ? x['uuid'] : undefined));
			for (let repair of shownRepairs()) {
				if(repair){
					if (ar.includes(repair['uuid'])) {
						activeRepairs.push(repair['uuid']);
					} else if (hasValidChildren(repair)) {
						for (let childRepair of repair['children']) {
							if (ar.includes(childRepair['uuid'])) {
								activeRepairs.push(childRepair['uuid']);
								activeRepairs.push(repair['uuid']);
							}
						}
					}
				}
			}
			return activeRepairs;
		});
	}, [itemRepairs, shownRepairs]);

	return (
		<div
			style={{
				height: '80%',
				width: '120%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-evenly',
			}}>
			{repairs &&
				shownRepairs(repairs, repairOffset).map((repair, repairIndex) => {
					if (repair) {
						return (
							<button
								style={{
									background: activeRepairs.includes(repair.uuid)
										? theme.primaryHover
										: theme.primaryLight,
									borderRadius: 'none',
									color: 'black',
									border: '3px solid black',
									fontSize: '12px',
									margin: '5px',
									maxWidth: '20vh',
									width: '100%',
									height: '9%',
								}}
								type='checkbox'
								onClick={() => {
									repairSelected(repair);
								}}
								key={repairIndex}>
								{repair.name + ' $' + repair.price}
							</button>
						);
					} else {
						return '';
					}
				})}
		</div>
	);
}

export default RepairSelectChecks;
