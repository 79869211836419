import SessionStorageManager from './sessionStorageManager';

export const ALERT_INFO = 'alertInfo';
export const MY_WORKING_ORDER = 'myWorkingOrder';
export const USER_ACCOUNT_INFO = 'accountInfo';
export const ORDER_TYPE = 'orderType';
export const SITE_ATTEMPTED_WITH_NO_LOGIN = 'site_attempted_with_no_login';
export const PAYMENT_INFO = 'paymentInfo';

class SessionStorage {
	constructor(itemName, type, defaultValue, ttl) {
		this.itemName = itemName;

		SessionStorageManager.addManagedStorage(itemName, type, defaultValue, ttl);
	}

	removeManagedStorage() {
		SessionStorageManager.removeManagedStorage(this.itemName);
	}

	addCallback(callback) {
		SessionStorageManager.addCallback(this.itemName, callback);
	}

	getDefaultValue() {
		return SessionStorageManager.getDefaultValue(this.itemName);
	}

	get(key = undefined) {
		return SessionStorageManager.get(this.itemName, key);
	}

	set(value, key) {
		SessionStorageManager.set(this.itemName, value, key);
	}

	add(value, key) {
		SessionStorageManager.add(this.itemName, value, key);
	}

	remove() {
		SessionStorageManager.remove(this.itemName);
	}

	reset() {
		SessionStorageManager.reset(this.itemName);
	}
}

export function createSessionStorage(
	itemName,
	type,
	defaultValue = undefined,
	ttl = undefined
) {
	return new SessionStorage(itemName, type, defaultValue, ttl);
}
