import {
	AccountApi,
	DropboxApi,
	OrderApi,
	OrderItemApi,
	OrderItemServiceLineItemApi,
	ProductApi,
	ProductImageApi,
	CategoryImageApi,
	TypeImageApi,
	LocationApi,
	UtilsApi
} from 'shoeber-backend-typescript-axios-v1';
import {
	createSessionStorage,
	USER_ACCOUNT_INFO,
} from '../utils/sessionStorageManager/sessionStorage';

class ShoeberBackendApi {
	static getConfiguration() {
		let config = {
			basePath:
				process.env.REACT_APP_BACKEND_URL +
				'/' +
				process.env.REACT_APP_API_VERSION,
		};

		const userInfoSessionManager = createSessionStorage(USER_ACCOUNT_INFO, 'json');

		let accessToken = userInfoSessionManager.get('accessToken');
		if (accessToken != null) {
			config.accessToken = accessToken;
		}
		return config;
	}

	static getAccountApi() {
		return new AccountApi(ShoeberBackendApi.getConfiguration());
	}

	static getDropboxApi() {
		return new DropboxApi(ShoeberBackendApi.getConfiguration());
	}

	static getOrderApi() {
		return new OrderApi(ShoeberBackendApi.getConfiguration());
	}

	static getOrderItemApi() {
		return new OrderItemApi(ShoeberBackendApi.getConfiguration());
	}

	static getOrderItemServiceLineItemApi() {
		return new OrderItemServiceLineItemApi(
			ShoeberBackendApi.getConfiguration()
		);
	}

	static getProductApi() {
		return new ProductApi(ShoeberBackendApi.getConfiguration());
	}

	static getProductImageApi() {
		return new ProductImageApi(ShoeberBackendApi.getConfiguration());
	}

	static getCategoryImageApi() {
		return new CategoryImageApi(ShoeberBackendApi.getConfiguration());
	}

	static getTypeImageApi() {
		return new TypeImageApi(ShoeberBackendApi.getConfiguration());
	}

	static getLocationApi() {
		return new LocationApi(ShoeberBackendApi.getConfiguration());
	}

	static getUtilsApi(){
		return new UtilsApi(ShoeberBackendApi.getConfiguration());
	}
}

export { ShoeberBackendApi };
