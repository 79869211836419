import { getDateTimeString, getDateTime } from './date';
import { isNumeric } from './number';

const EXPIRE_TIME_PARAM_NAME = 'globalExpireTime';
const EXPIRABLE_ITEMS = 'expirableItems';
const MANAGED_ITEMS = 'managedItems';
const TIME_TO_LIVE = process.env.REACT_APP_TTL || 86400000;

export default class StorageManager {
	managedStorage = {};

	addManagedStorage(itemName, type, defaultValue = undefined, ttl = undefined) {
		this.addManagedItem(itemName);
		if (this.managedStorage[itemName] === undefined) {
			this.managedStorage[itemName] = {
				type: type,
				defaultValue: defaultValue,
				callbacks: [],
				ttl,
			};

			let rawCurrentValue = this.getImpl(itemName);
			if (
				rawCurrentValue !== null &&
				rawCurrentValue !== undefined &&
				(process.env.REACT_APP_CLEAR_LOCAL_STORAGE_ON_RELOAD === undefined ||
					(process.env.REACT_APP_CLEAR_LOCAL_STORAGE_ON_RELOAD !== undefined &&
						JSON.parse(
							process.env.REACT_APP_CLEAR_LOCAL_STORAGE_ON_RELOAD.toLowerCase()
						) !== true))
			) {
				try {
					let currentValueValue = JSON.parse(rawCurrentValue);
					if (currentValueValue === undefined) {
						this.reset(itemName);
					}
				} catch {
					this.reset(itemName);
				}
			} else {
				this.reset(itemName);
			}
		}
	}

	addManagedItem(itemName) {
		let managedItems = this.getManagedItems();

		if (!managedItems.includes(itemName)) {
			managedItems.push(itemName);
			localStorage.setItem(MANAGED_ITEMS, JSON.stringify(managedItems));
		}
	}

	getManagedItems() {
		try {
			return JSON.parse(localStorage.getItem(MANAGED_ITEMS)) || [];
		} catch {
			return [];
		}
	}

	removeManagedStorage(itemName) {
		if (this.managedStorage[itemName] !== undefined) {
			this.removeImpl(itemName);
			delete this.managedStorage[itemName];
		}
	}

	addCallback(itemName, callback) {
		if (this.managedStorage[itemName] !== undefined) {
			this.managedStorage[itemName].callbacks.push(callback);
			callback();
		}
	}

	getDefaultValue(itemName) {
		if (this.managedStorage[itemName] !== undefined) {
			if (this.managedStorage[itemName].defaultValue !== undefined) {
				return this.managedStorage[itemName].defaultValue;
			} else {
				switch (this.managedStorage[itemName].type.toLowerCase()) {
					case 'json':
						return {};
					case 'array':
						return [];
					case 'bool':
						return false;
					case 'number':
						return NaN;
					case 'dateTime':
						return new Date();
					default:
						return '';
				}
			}
		} else {
			return undefined;
		}
	}

	get(itemName, key = undefined) {
		if (this.managedStorage[itemName] !== undefined) {
			this.refreshTTL(itemName);
			let currentValue = JSON.parse(this.getImpl(itemName));

			if (currentValue === null) {
				currentValue = this.getDefaultValue(itemName);
			}

			switch (this.managedStorage[itemName].type.toLowerCase()) {
				case 'bool':
					return currentValue === 'true';
				case 'number':
					return parseInt(currentValue);
				case 'array':
					if (key !== undefined) {
						return currentValue[key];
					} else {
						return currentValue;
					}
				case 'json':
					if (key !== undefined) {
						return currentValue[key];
					} else {
						return currentValue;
					}
				case 'dateTime':
					return getDateTime(currentValue);
				default:
					return currentValue;
			}
		} else {
			return undefined;
		}
	}

	set(itemName, value, key = undefined) {
		if (this.managedStorage[itemName] !== undefined) {
			this.refreshTTL(itemName);
			let newValue;
			let currentData = this.get(itemName);
			switch (this.managedStorage[itemName].type.toLowerCase()) {
				case 'json':
					if (key !== undefined) {
						currentData[key] = value;
						newValue = currentData;
						break;
					} else {
						newValue = value;
						break;
					}
				case 'dateTime':
					newValue = getDateTimeString(value);
					break;
				default:
					newValue = value;
			}

			this.setImpl(itemName, JSON.stringify(newValue));

			let ttl = this.managedStorage[itemName].ttl;
			if (ttl !== undefined) {
				this.setExpiry(itemName, ttl);
			}

			this.managedStorage[itemName].callbacks.forEach((callback) => {
				callback();
			});
		}
	}

	add(itemName, value, key) {
		if (this.managedStorage[itemName] !== undefined) {
			this.refreshTTL(itemName);
			let newValue;
			let currentData = this.get(itemName);
			switch (this.managedStorage[itemName].type.toLowerCase()) {
				case 'json':
					currentData[key] = value;
					newValue = currentData;
					break;
				case 'array':
					newValue = [...currentData, value];
					break;
				default:
					newValue = value + '';
			}

			this.setImpl(itemName, JSON.stringify(newValue));

			this.managedStorage[itemName].callbacks.forEach((callback) => {
				callback();
			});
		}
	}

	reset(itemName) {
		if (this.managedStorage[itemName] !== undefined) {
			let jsonStringValue = JSON.stringify(this.getDefaultValue(itemName));

			this.setImpl(itemName, jsonStringValue);

			this.managedStorage[itemName].callbacks.forEach((callback) => {
				callback();
			});
		}
	}

	globalRefresh() {
		let expireTime = localStorage.getItem(EXPIRE_TIME_PARAM_NAME);

		if (
			!expireTime ||
			!isNumeric(expireTime) ||
			new Date().getTime() > parseInt(expireTime)
		) {
			this.getManagedItems().forEach((itemName) => {
				sessionStorage.removeItem(itemName);
				localStorage.removeItem(itemName);
			});
		}
	}

	refreshTTL(itemName) {
		this.globalRefresh();

		// Check item expiry
		if (this.isItemExpired(itemName)) {
			this.removeImpl(itemName);
			this.removeExpiry(itemName);
		}

		localStorage.setItem(
			EXPIRE_TIME_PARAM_NAME,
			new Date().getTime() + TIME_TO_LIVE
		);
	}

	removeExpiry(itemName) {
		let currentExpiryItems = this.getExpirableItems();
		delete currentExpiryItems[itemName];
		localStorage.setItem(EXPIRABLE_ITEMS, JSON.stringify(currentExpiryItems));
	}

	setExpiry(itemName, ttl) {
		let newExpireItem = {};
		newExpireItem[itemName] = new Date().getTime() + ttl;

		localStorage.setItem(
			EXPIRABLE_ITEMS,
			JSON.stringify(Object.assign({}, this.getExpirableItems(), newExpireItem))
		);
	}

	getExpirableItems() {
		try {
			return JSON.parse(localStorage.getItem(EXPIRABLE_ITEMS) || {});
		} catch {
			return {};
		}
	}

	isItemExpired(itemName) {
		let expirableItems = this.getExpirableItems();

		if (expirableItems[itemName] === undefined) {
			return false;
		} else {
			let expireTime = expirableItems[itemName];
			return (
				!isNumeric(expireTime) || new Date().getTime() > parseInt(expireTime)
			);
		}
	}
}
