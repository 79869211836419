import React, { useEffect, useState } from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './SideNav.styled';
import { logoutUser } from '../../../utils/login';
import { Container } from 'react-bootstrap';
import LoadOnce from '../../../hooks/loadOnce';
import {
	createSessionStorage,
	USER_ACCOUNT_INFO,
} from '../../../utils/sessionStorageManager/sessionStorage';
import styled from 'styled-components';
import { getLocation } from '../../../utils/url';

const StyledSideBarLink = styled.div`
	a {
		color: ${(props) =>
			props.active
				? ({ theme }) => theme.primaryHover
				: ({ theme }) => theme.primaryLight};
	}
`;

export default function SideNav({ openSideMenu, setOpenSideMenu }) {
	const [userType, setUserType] = useState(undefined);

	const userInfoSessionManager = createSessionStorage(
		USER_ACCOUNT_INFO,
		'json'
	);

	const pageLoad = LoadOnce();

	function getMenuLink(to, displayName) {
		return { to: to, displayName: displayName };
	}

	function menuLinks() {
		let menuLinks = [
			getMenuLink('/', 'Add Item'),
			getMenuLink('/locations', 'View Locations'),
		];

		if (userType === undefined) {
			menuLinks.push(getMenuLink('/login', 'Login'));
			menuLinks.push(getMenuLink('/signUp', 'Create Account'));
		} else {
			menuLinks.push(getMenuLink('/my/orders', 'Orders'));
			menuLinks.push(getMenuLink('/profile', 'Profile'));

			if (userType === 'admin' || userType === 'employee') {
				menuLinks.push(getMenuLink('/employee', 'Employee'));
			}

			if (userType === 'admin') {
				menuLinks.push(getMenuLink('/admin', 'Admin'));
			}
		}
		return menuLinks;
	}

	function handleLogout() {
		setOpenSideMenu(false);
		logoutUser();
	}

	useEffect(() => {
		if (pageLoad.canLoad()) {
			userInfoSessionManager.addCallback(() => {
				setUserType(userInfoSessionManager.get('userType'));
			});
		}
	}, [pageLoad, userInfoSessionManager]);

	return (
		<Container>
			<StyledMenu open={openSideMenu}>
				{menuLinks().map((link) => {
					return (
						<StyledSideBarLink key={link.to} active={link.to === getLocation()}>
							<a onClick={() => setOpenSideMenu(false)} href={'/#' + link.to}>
								{link.displayName}
							</a>
						</StyledSideBarLink>
					);
				})}
				{userType !== undefined && (
					<a href='/#/' onClick={() => handleLogout()}>
						Logout
					</a>
				)}
			</StyledMenu>
		</Container>
	);
}

SideNav.propTypes = {
	openSideMenu: bool.isRequired,
};
