import {
	createSessionStorage,
	USER_ACCOUNT_INFO,	
} from './sessionStorageManager/sessionStorage';

import SessionStorageManager from './sessionStorageManager/sessionStorageManager';

export function setLogin(token, ttlInMillis, userType) {
	const updateTime = Date.now() + ttlInMillis - 60000;
	
	createSessionStorage(USER_ACCOUNT_INFO, 'json').set({
		accessToken: token,
		updateTime: updateTime,
		userType: userType,
	});
}

export function logout() {
	SessionStorageManager.clear();
}

export function logoutUser() {
	logout();
	window.location.reload(false);
}
