export function formatPhoneNumberDisplay(phoneNumber) {
	if (phoneNumber) {
		phoneNumber = phoneNumber.replace(/\D/g, '');

		if (phoneNumber.length < 11) {
			return phoneNumber.replace(/(\d{3})(\d{3})(\d+)/, '($1)-$2-$3');
		} else {
			return phoneNumber
				.replace(/(\d{1})(\d{3})(\d{3})(\d+)/, '$1-($2)-$3-$4')
				.substring(0, 16);
		}
	}
	return phoneNumber;
}

export function formatPhoneNumber(phoneNumberDisplay) {
	if (!phoneNumberDisplay) {
		return '';
	}
	
	phoneNumberDisplay = phoneNumberDisplay.replace(/\D/g, '');
	if (phoneNumberDisplay.length === 10) {
		phoneNumberDisplay = '1' + phoneNumberDisplay;
	}

	return '+' + phoneNumberDisplay;
}
