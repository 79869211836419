import React from 'react';
import { Modal } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import { format } from 'react-string-format';
import ShoeberButton from '../Core/ShoeberButton';
import ModalBackButton from '../Core/Modal/ModalBackButton';
import ModalCloseButton from '../Core/Modal/ModalCloseButton';
import './LocationModal.css';

export default function LocationModal({ isShowing, toggle, info }) {
	const location = info.location !== undefined ? info.location : {}
	
	return (
		<Modal show={isShowing} animation={false}>
			<Modal.Header>
				<ModalBackButton onClick={toggle} />
				<Modal.Title />
				<ModalCloseButton onClick={toggle} />
			</Modal.Header>
			<Modal.Body style={{ textAlign: 'left' }}>
				<Row>
					<Col>
						<img
							alt={location.address_one}
							src={info.image}
							style={{ maxWidth: '100%' }}
						/>
					</Col>
					<Col>
						<p>
							{location.address_one}
							{location.address_two != null &&
								format(' {0}', location.address_two)}
						</p>
						<p>
							{location.city} {location.state}
						</p>
						<p>{location.postal_code}</p>
						<p>{location.country}</p>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<ShoeberButton onClick={toggle} text='Close' />
			</Modal.Footer>
		</Modal>
	);
}
