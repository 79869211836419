export const CODE_URL_PARAM_NAME = 'code';
export const API_KEY_URL_PARAM_NAME = 'api_key';

export function urlParam(paramName) {
	let urlArray = window.location.href.split('?');
	if (urlArray.length === 2) {
		let params = urlArray[1].split('&');
		let value = undefined;
		params.forEach((param) => {
			if (param.includes('=')) {
				let paramValueArray = param.split('=', 2);
				if (paramValueArray[0] === paramName) {
					value = paramValueArray[1];
				}
			}
		});
		return value;
	} else {
		return undefined;
	}
}

export function hasParam(paramName){
	return urlParam(paramName) !== undefined
}

export function getLocation(includeUrlPrams = false) {
	let locationArray = window.location.href.split('/#');
	if (locationArray.length === 2) {
		if (includeUrlPrams === true) {
			return locationArray[1];
		} else {
			return locationArray[1].split('?')[0];
		}
	} else {
		return undefined;
	}
}
