import { useEffect, useState } from 'react';
import {
	createSessionStorage,
	USER_ACCOUNT_INFO,
} from '../utils/sessionStorageManager/sessionStorage';
import LoadOnce from './loadOnce';

export const STRIPE_TOKEN_ID = 'strip_token_id';
export const STRIP_PAYMENT_METHOD_ID = 'strip_payment_method_id';
export const STRIP_PAYMENT_METHOD_NAME = 'strip_payment_method_name';

export default function usePayments() {

	// TODO: Use paymentInfo
	// eslint-disable-next-line no-unused-vars
	const [paymentInfo, setPaymentInfo] = useState(undefined);

	const userAccountInfoSessionManager = createSessionStorage(
		USER_ACCOUNT_INFO,
		'json'
	);

	// TODO: Use setPaymentErrors
	// eslint-disable-next-line no-unused-vars
	const [paymentErrors, setPaymentErrors] = useState({});

	const addWorkingSessionManager = LoadOnce();

	// TODO: Use userInfo
	// eslint-disable-next-line no-unused-vars
	const [userInfo, setUserInfo] = useState(true);

	useEffect(() => {
		if (addWorkingSessionManager.canLoad()) {
			userAccountInfoSessionManager.addCallback(() => {
				setUserInfo(userAccountInfoSessionManager.get());
			});
		}
	});

	function getTokenFromPaymentInfo(cardData) {
		// TODO: Add strip integration
		// TODO: Add token from card info
		// TODO: Save payment info if user desired
		// TODO: Add token from already saved payment
		return 'needs impl';
	}

	return {
		getTokenFromPaymentInfo,
		paymentErrors,
	};
}
