import { React, useState, useEffect } from 'react';
import Styles from './Styles';
import Card from './Card';
import Payment from 'payment';
import { useInput } from '../../hooks/input';
import ShoeberButton from '../Core/ShoeberButton';
import { Form, Row, Col } from 'react-bootstrap';
import LoadOnce from '../../hooks/loadOnce';

export const CARD_NUMBER = 'card_number';
export const CARD_NAME = 'card_name';
export const CARD_EXPIRY = 'card_expiry';
export const CARD_CVC = 'card_cvc';


function AccountPayment({ saveCardData, errorMessageHash, cardData }) {

	const numberInput = useInput('');

	const nameInput = useInput('');

	const expiryInput = useInput('');

	const cvcInput = useInput('');

	const [focused, setFocused] = useState('');

	const setInitialCardData = LoadOnce();

	useEffect(() => {
		if (Object.keys(cardData).length > 0 && setInitialCardData.canLoad()) {
			numberInput.setValue(cardData[CARD_NUMBER] || '');
			nameInput.setValue(cardData[CARD_NAME] || '');
			expiryInput.setValue(cardData[CARD_EXPIRY] || '');
			cvcInput.setValue(cardData[CARD_CVC] || '');
		}
	});

	function clearNumber(value = '') {
		return value.replace(/\D+/g, '');
	}

	function formatCreditCardNumber(value) {
		if (!value) {
			return value;
		}

		const issuer = Payment.fns.cardType(value);
		const clearValue = clearNumber(value);
		let nextValue;

		switch (issuer) {
			case 'amex':
				nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
					4,
					10
				)} ${clearValue.slice(10, 15)}`;
				break;
			case 'dinersclub':
				nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
					4,
					10
				)} ${clearValue.slice(10, 14)}`;
				break;
			default:
				nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
					4,
					8
				)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
				break;
		}

		return nextValue.trim();
	}

	function formatCVC(value, prevValue, allValues = {}) {
		const clearValue = clearNumber(value);
		let maxLength = 4;

		if (allValues.number) {
			const issuer = Payment.fns.cardType(allValues.number);
			maxLength = issuer === 'amex' ? 4 : 3;
		}

		return clearValue.slice(0, maxLength);
	}

	function formatExpirationDate(value) {
		const clearValue = clearNumber(value);

		if (clearValue.length >= 3) {
			return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
		}

		return clearValue;
	}

	function handleCardSave(send = true) {
		let cardInfo = {};
		cardInfo[CARD_NUMBER] = send ? numberInput.value : '';
		cardInfo[CARD_NAME] = send ? nameInput.value : '';
		cardInfo[CARD_EXPIRY] = send ? expiryInput.value : '';
		cardInfo[CARD_CVC] = send ? cvcInput.value : '';
		saveCardData(cardInfo);
	}

	return (
		<Styles>
			<div className='cardForm'>
				<Row>
					<Col>
						<Card
							number={numberInput.value}
							name={nameInput.value}
							expiry={expiryInput.value}
							cvc={cvcInput.value}
							focused={focused}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Group>
							<Form.Control
								type='text'
								{...numberInput.bind}
								placeholder='Card Number'
								onChange={(event) =>
									numberInput.setValue(
										formatCreditCardNumber(event.target.value)
									)
								}
								onFocus={() => {
									setFocused('number');
								}}
								onBlur={() => {
									setFocused(undefined);
								}}
								pattern='[\d| ]{16,22}'
								isInvalid={errorMessageHash[CARD_NUMBER]}
							/>
							<Form.Control.Feedback type='invalid'>
								{errorMessageHash[CARD_NUMBER]}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Group>
							<Form.Control
								type='text'
								{...nameInput.bind}
								placeholder='Name'
								name='name'
								onFocus={() => {
									setFocused('name');
								}}
								onBlur={() => {
									setFocused(undefined);
								}}
								isInvalid={errorMessageHash[CARD_NAME]}
							/>
							<Form.Control.Feedback type='invalid'>
								{errorMessageHash[CARD_NUMBER]}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Group>
							<Form.Control
								type='text'
								{...expiryInput.bind}
								placeholder='Valid Thru'
								name='expiry'
								onFocus={() => {
									setFocused('expiry');
								}}
								onBlur={() => {
									setFocused(undefined);
								}}
								onChange={(event) =>
									expiryInput.setValue(formatExpirationDate(event.target.value))
								}
								isInvalid={errorMessageHash[CARD_EXPIRY]}
							/>
							<Form.Control.Feedback type='invalid'>
								{errorMessageHash[CARD_EXPIRY]}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>

					<Col>
						<Form.Group>
							<Form.Control
								type='text'
								{...cvcInput.bind}
								name='cvc'
								placeholder='CVC'
								onChange={(event) =>
									cvcInput.setValue(formatCVC(event.target.value))
								}
								onFocus={() => {
									setFocused('cvc');
								}}
								onBlur={() => {
									setFocused(undefined);
								}}
								pattern='[\d| ]{16,22}'
								isInvalid={errorMessageHash[CARD_CVC]}
							/>
							<Form.Control.Feedback type='invalid'>
								{errorMessageHash[CARD_CVC]}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>
				<div className='buttons'>
					<ShoeberButton onClick={handleCardSave} text='Save' />
					<ShoeberButton
						secondary
						text='Reset'
						onClick={() => {
							numberInput.reset();
							nameInput.reset();
							expiryInput.reset();
							cvcInput.reset();
							handleCardSave(false);
						}}
					/>
				</div>
			</div>
		</Styles>
	);
}
export default AccountPayment;
