import styled from 'styled-components';

export const StyledMenu = styled.div`
	display: flex;
	flex-direction: column;
	background: ${({ theme }) => theme.primaryDark};
	padding: 2rem;
	position: fixed;
	top: ${({ theme }) => theme.menuHeight-1 + 'px'};
	left: 0;
	transition: transform 0.3s ease-in-out;
	z-index: 8;
	max-width: 100%;
	min-height 350px;

	button {
		text-transform: uppercase;
		padding: 0px;
		font-weight: bold;
		letter-spacing: 0.5rem;
		background-color: transparent;
		text-align: left;
		color: ${({ theme }) => theme.primaryLight};
		border: none;

		&:hover {
			color: ${({ theme }) => theme.primaryHover};
			background-color: transparent;
			border: none;
		}

		&:focus {
			outline-width: 0px;
		}

		&:active {
			background-color: transparent;
			border: none;
		}
	}

	a {
		text-transform: uppercase;
		padding: 0.5rem 0;
		font-weight: bold;
		letter-spacing: 0.5rem;
		color: ${({ theme }) => theme.primaryLight};
		text-decoration: none;
		transition: color 0.3s linear;
		&:hover {
			color: ${({ theme }) => theme.primaryHover};
		}
	}
	transform: translateX(-100%);
	transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
`;
