import React from 'react';
import { bool, func } from 'prop-types';
import { StyledBurger } from './Burger.styled';
import { withRouter } from 'react-router-dom';
import { Container } from 'react-bootstrap';

const Burger = ({
	open,
	setOpen,
	setOpenRunningReceipt,
	openRunningReceipt,
}) => {
	function handleOpen() {
		setOpen(!open);
		setOpenRunningReceipt(false);
	}

	return (
		<Container>
			<StyledBurger onClick={() => handleOpen()}>
				<div />
				<div />
				<div />
			</StyledBurger>
		</Container>
	);
};

Burger.propTypes = {
	open: bool.isRequired,
	setOpen: func.isRequired,
};

export default withRouter(Burger);
