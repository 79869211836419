import { useHistory } from 'react-router-dom';
import {SECONDARY} from '../Components/Core/ShoeberAlert'
import useAlert from './useAlert'
 

export default function useRedirectWithAlert() {
	const alert = useAlert();
	const history = useHistory();

	function redirectWithAlert(
		to,
		title,
		message,
		variant = SECONDARY,
		timeout = 4000
	) {
		alert.sendAlert(title, message, variant, timeout);
		history.push(to);
	}

	return {
		redirectWithAlert,
	};
}
