import StorageManager from '../storageManager';

class SessionStorageManager extends StorageManager {
	constructor() {
		super();
		if (!SessionStorageManager.instance) {
			SessionStorageManager.instance = this;
		}

		return SessionStorageManager.instance;
	}

	getImpl(itemName) {
		return sessionStorage.getItem(itemName);
	}

	setImpl(itemName, value) {
		sessionStorage.setItem(itemName, value);
	}

	removeImpl(itemName) {
		sessionStorage.removeItem(itemName);
	}

	clear() {
		sessionStorage.clear();
	}
}

const instance = new SessionStorageManager();
Object.freeze(instance);
export default instance;
