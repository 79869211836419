import { theme } from '../../theme';

const ShoeberLine = () => {
	return (
		<hr
			style={{
				color: theme.primaryLight,
				backgroundColor: theme.primaryLight,
				width: '100%',
				margin: '4px',
			}}
		/>
	);
};

export default ShoeberLine;