import React, { useState } from 'react';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { BsClipboard } from 'react-icons/bs';

function NotesPopover(props) {
	let [input, setInput] = useState('');
	let [show, setShow] = useState(false);

	const setRepairNotes = (e) => {
		// e.preventDefault();
	};

	const notes = (
		<Popover>
			<Popover.Title>Notes</Popover.Title>
			<Popover.Content
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-evenly',
					alignItems: 'center',
					width: '100%',
					height: '20vh',
				}}>
				<textarea
					style={{ resize: 'none', fontSize: '.8rem' }}
					rows={5}
                    
					onChange={(e) => setInput(e.target.value)}
				/>
				<button
					style={{ width: '50%' }}
					onClick={() => {
						setShow(false);
						setRepairNotes();
					}}>
					save
				</button>
			</Popover.Content>
		</Popover>
	);

	return (
		<OverlayTrigger
			show={show}
			placement='left'
			overlay={notes}
			trigger='click'>
			<props.StyledIcon style={{ marginRight: '20px' }}>
				<BsClipboard onClick={() => show ? setShow(false) : setShow(true)}/>
			</props.StyledIcon>
		</OverlayTrigger>
	);
}

export default NotesPopover;
