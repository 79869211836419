import ShoeberAlert, { SECONDARY } from './ShoeberAlert';
import LoadOnce from '../../hooks/loadOnce';
import { useState, useEffect, useRef } from 'react';
import {
	createSessionStorage,
	ALERT_INFO,
} from '../../utils/sessionStorageManager/sessionStorage';

export default function AlertManager() {
	const [show, setShow] = useState(false);
	const [title, setTitle] = useState(undefined);
	const [message, setMessage] = useState(undefined);
	const [alertVariant, setAlertVariant] = useState(undefined);
	const [timeout, setTimeout] = useState(4000);

	const alertInfoSessionStorage = createSessionStorage(ALERT_INFO, 'json');

	const alertRef = useRef();

	const displayAlertLoad = LoadOnce();

	useEffect(() => {
		if (displayAlertLoad.canLoad()) {
			alertInfoSessionStorage.addCallback(() => {
				let alertInfo = alertInfoSessionStorage.get();
				if (alertInfo.display === true) {
					setTitle(alertInfo.title);
					setMessage(alertInfo.message);
					setTimeout(alertInfo.timeout || 4000);
					setAlertVariant(alertInfo.variant || SECONDARY);
					setShow(true);
					alertInfoSessionStorage.set(false, 'display');
				}
			});
		}
	}, [alertInfoSessionStorage, displayAlertLoad]);

	return (
		<div ref={alertRef}>
			{show && (
				<ShoeberAlert
					show={show}
					setShow={setShow}
					title={title}
					message={message}
					timeout={timeout}
					variant={alertVariant}
				/>
			)}
		</div>
	);
}
