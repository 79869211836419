import React, { useRef, useState, useEffect } from 'react';
import { Navbar, NavDropdown, Container } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import LoadOnce from '../../../hooks/loadOnce';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '../../../utils/login';
import ShoeberShoppingCart from '../../Core/ShoeberShoppingCart/ShoeberShoppingCart';
import {
	createLocalStorage,
	MY_REPAIR_ITEMS_NAME,
} from '../../../utils/localStorageManager/localStorage';
import {
	createSessionStorage,
	USER_ACCOUNT_INFO,
} from '../../../utils/sessionStorageManager/sessionStorage';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import RunningReceipt from '../../RunningReceipt/RunningReceipt';
import styled from 'styled-components';
import { getLocation } from '../../../utils/url';
import { StyledMenuButton } from '../Navigation.styled';

const StyledBarMenuLink = styled.div`
	a:first-child {
		color: ${(props) =>
			props.active
				? ({ theme }) => theme.primaryHover
				: ({ theme }) => theme.primaryLight};
	}

	a:first-child.nav-link.active {
		color: ${(props) =>
			props.active
				? ({ theme }) => theme.primaryHover
				: ({ theme }) => theme.primaryLight};
	}

	a:first-child.nav-link {
		color: ${(props) =>
			props.active
				? ({ theme }) => theme.primaryHover
				: ({ theme }) => theme.primaryLight};
	}
`;

function BarNav({
	openRunningReceipt,
	setOpenRunningReceipt,
	setActiveEditItem,
}) {
	const [userType, setUserType] = useState(undefined);

	const userInfoSessionManager = createSessionStorage(
		USER_ACCOUNT_INFO,
		'json'
	);

	const runningReceiptRef = useRef();

	const myCartLocalStorage = createLocalStorage(MY_REPAIR_ITEMS_NAME, 'array');

	useOnClickOutside(runningReceiptRef, () => setOpenRunningReceipt(false));

	const [myCart, setMyCart] = useState([]);

	const pageLoad = LoadOnce();

	useEffect(() => {
		if (pageLoad.canLoad()) {
			myCartLocalStorage.addCallback(() => setMyCart(myCartLocalStorage.get()));

			userInfoSessionManager.addCallback(() => {
				setUserType(userInfoSessionManager.get('userType'));
			});
		}
	}, [myCartLocalStorage, pageLoad, userInfoSessionManager]);

	return (
		<Container>
			<Navbar className='navColor'>
				<Navbar.Brand href='#/home'>
					{/* <img
						alt='Shoeber Location'
						src='https://s3.us-east-2.amazonaws.com/shoeber.html.helpers.graffititech.co/images/static/shoeber.png'
						height='40'
					/> */}
				</Navbar.Brand>
				<Nav className='mr-auto'>
					<NavDropdown  title='Shoeber'>
						<StyledBarMenuLink active={'/home' === getLocation()}>
							<NavDropdown.Item href='#/home'>Add Item</NavDropdown.Item>
						</StyledBarMenuLink>
						<NavDropdown.Divider />
						<StyledBarMenuLink active={'/locations' === getLocation()}>
							<NavDropdown.Item href='#/locations'>
								View Locations
							</NavDropdown.Item>
						</StyledBarMenuLink>
					</NavDropdown>
					{userType !== undefined && (
						<StyledBarMenuLink active={'/my/orders' === getLocation()}>
							<Nav.Link href='#/my/orders'>Orders</Nav.Link>
						</StyledBarMenuLink>
					)}
				</Nav>

				<Nav>
					<div ref={runningReceiptRef}>
						<StyledMenuButton>
							<Nav.Link
								onClick={() => setOpenRunningReceipt(!openRunningReceipt)}>
								<ShoeberShoppingCart />
							</Nav.Link>
						</StyledMenuButton>
						<RunningReceipt
							open={openRunningReceipt}
							setOpenRunningReceipt={setOpenRunningReceipt}
							setActiveEditItem={setActiveEditItem}
						/>
					</div>
				</Nav>

				{/* <Nav>
					<Nav.Link href='#/checkout' disabled={myCart.length === 0}>
						<ShoeberShoppingCart />
					</Nav.Link>
				</Nav> */}

				<Nav>
					{userType !== undefined && (
						<NavDropdown title='Profile'>
							<StyledBarMenuLink active={'/profile' === getLocation()}>
								<NavDropdown.Item href='#/profile'>Profile</NavDropdown.Item>
							</StyledBarMenuLink>

							{(userType === 'admin' || userType === 'employee') && (
								<StyledBarMenuLink active={'/employee' === getLocation()}>
									<NavDropdown.Item href='#/employee'>
										Employee
									</NavDropdown.Item>
								</StyledBarMenuLink>
							)}
							{userType === 'admin' && (
								<StyledBarMenuLink active={'/admin' === getLocation()}>
									<NavDropdown.Item href='#/admin'>Admin</NavDropdown.Item>
								</StyledBarMenuLink>
							)}

							<NavDropdown.Divider />
							<StyledMenuButton>
								<NavDropdown.Item onClick={logoutUser}>Logout</NavDropdown.Item>
							</StyledMenuButton>
						</NavDropdown>
					)}
					{userType === undefined && (
						<NavDropdown title='Sign In' className='nav-dropdown-right'>
							<StyledBarMenuLink active={'/login' === getLocation()}>
								<NavDropdown.Item href='#/login'>Sign In</NavDropdown.Item>
							</StyledBarMenuLink>
							<NavDropdown.Divider />
							<StyledBarMenuLink active={'/signup' === getLocation()}>
								<NavDropdown.Item href='#/signup'>
									Create Account
								</NavDropdown.Item>
							</StyledBarMenuLink>
						</NavDropdown>
					)}
				</Nav>
			</Navbar>
		</Container>
	);
}

export default withRouter(BarNav);
